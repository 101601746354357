import { render, staticRenderFns } from "./revisit.vue?vue&type=template&id=5a14d554&"
import script from "./revisit.vue?vue&type=script&lang=js&"
export * from "./revisit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports