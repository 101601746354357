<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="车位号">
          <el-input v-model="searchData.seatNum" placeholder="请输入车位号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.getHaveEvidenceOrderList " :data="searchData" ref="table" width="200">
      <el-table-column fixed prop="id" label="订单ID" width="160"></el-table-column>
      <el-table-column prop="parkingName" label="停车场" min-width="180"></el-table-column>
      <el-table-column prop="seatNum" label="车位编号" min-width="130"></el-table-column>
      <el-table-column prop="createTime" label="进场时间" min-width="150"></el-table-column>
      <el-table-column prop="updateTime" label="取证时间" min-width="150"></el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template slot-scope="scope">
          <el-button type="text" @click="updateOrderToUnHaveEvidenceOrder(scope.row.id)">重新取证</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "orderForensics",
  title: "已取证订单",
  data() {
    return {
      searchData: {
        seatNum: "",
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
    };
  },
  methods: {
    updateOrderToUnHaveEvidenceOrder(id) {
      this.$http.get(
        this.$api.updateOrderToUnHaveEvidenceOrder,
        { id },
        (res) => {
          this.$message.success("状态修改成功！");
          this.$refs.table.reloadData();
        }
      );
    },
  },
};
</script>
