<template>
  <div style="display: inline-block;margin-left: 10px;">
    <el-button @click="getOrderDetailLine()" type="text">订单时间线</el-button>
    <el-drawer title="停车订单时间线" size="480px" :modal="false" :visible.sync="drawer" :append-to-body="true" :destroy-on-close="true">
      <div style="height: calc(100% - 80px);overflow-y: auto;padding-right: 20px;">
        <el-timeline style="padding-left: 20px;">
          <el-timeline-item :timestamp="it.time" placement="top" v-for="(it,key) in line" :key="key">
            <el-card>
              <h4><span v-if="it.userName">【操作人员：{{ it.userName }}】</span>{{ it.remark }}</h4>
              <div style="margin-top: 10px;">
                <el-image v-for="(img,imgKey) in it.imgsArray" :key="imgKey" style="width: 100px; height: 100px;margin-right: 10px;" :src="img" :preview-src-list="it.imgsArray"></el-image>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "cui-timeline",
  props: ["id"],
  data() {
    return {
      drawer: false,
      line: [],
    };
  },
  methods: {
    getOrderDetailLine() {
      const id = this.id;
      if (id) {
        this.$http.get(this.$api.queryOrderTimeDetail, { id }, (res) => {
          res.list.forEach((e) => {
            e.imgsArray = [];
            if (e.imgs) {
              let { bodyImgUrl, plateImgUrl, seatImgUrl } = JSON.parse(e.imgs);
              if (bodyImgUrl) e.imgsArray.push(bodyImgUrl);
              if (plateImgUrl) e.imgsArray.push(plateImgUrl);
              if (seatImgUrl) e.imgsArray.push(seatImgUrl);
            }
          });
          this.line = res.list;
          this.drawer = true;
        });
      } else {
        this.$message.info("订单ID异常！");
      }
    },
  },
};
</script>

<style>
</style>