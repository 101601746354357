<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="来电号码">
        <el-input v-model="consultationData.mobile" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="来电姓名">
        <el-input v-model="consultationData.userName" placeholder="请输入来电人姓名"></el-input>
      </el-form-item>
      <el-form-item label="关联车牌">
        <el-input v-model="consultationData.plate" placeholder="请输入关联车牌"></el-input>
      </el-form-item>
      <el-form-item label="咨询类型">
        <el-select v-model="consultationData.consultationType">
          <el-option v-for="item in consultationTypeList" :key="item.id" :label="item.value" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="咨询内容">
        <el-input v-model="consultationData.content" type="textarea" :rows="8" placeholder="请输入内容">
        </el-input>
      </el-form-item>
      <el-form-item label="回复内容">
        <el-input v-model="consultationData.reply" type="textarea" :rows="8" placeholder="请输入内容">
        </el-input>
      </el-form-item>
      <el-form-item label="是否解决">
        <el-radio-group v-model="consultationData.status">
          <el-radio :label="10" border>未解决</el-radio>
          <el-radio :label="20" border>已解决</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="insertConsultation" type="primary">提交</el-button>
        <el-button type="danger" @click="Object.assign($data.consultationData,$options.data().consultationData)">清空</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createRecordConsultation",
  title: "新建来电咨询记录",
  data() {
    return {
      consultationData: {
        mobile: "",
        userName: "",
        plate: "",
        consultationType: "",
        content: "",
        reply: "",
        status: 10,
      },
      consultationTypeList: [],
    };
  },
  created() {
    this.consultationData.mobile = this.$store.state.caller || "";
    this.selectItemList();
  },
  methods: {
    insertConsultation() {
      this.$http.post(
        this.$api.insertConsultation,
        this.consultationData,
        (res) => {
          this.$message.success("来电咨询记录添加成功！");
          Object.assign(
            this.$data.consultationData,
            this.$options.data().consultationData
          );
          this.$root.$emit("closeWorkorderDialog");
        }
      );
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "consultation",
        },
        (res) => {
          this.consultationTypeList = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>