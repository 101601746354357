<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="用户名：">
          <el-input v-model="searchData.username" placeholder="请输入用户名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="投诉类型">
          <el-select v-model="searchData.complainTypeId" placeholder="请选择">
            <el-option v-for="item in complainTypeData" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投诉来源：">
          <el-select v-model="searchData.clientType" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="客服中心" value="60"></el-option>
            <el-option label="APP" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option value label="全部"></el-option>
            <el-option value="0" label="未解决"></el-option>
            <el-option value="1" label="已解决"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
          <el-button @click="Object.assign($data.searchData,$options.data().searchData)">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.selectHistoryComplaint" :data="searchData" ref="table" width="200">
      <el-table-column label="序号" type="index" min-width="60"></el-table-column>
      <el-table-column prop="username" label="用户名" width="100">
        <template slot-scope="scope">{{scope.row.username?scope.row.username:scope.row.nickName}}</template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" width="100"></el-table-column>
      <el-table-column label="投诉来源" width="100">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.clientType==60" type="success">客服中心</el-tag>
          <el-tag size="medium" v-else>APP</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="parkName" label="被投诉停车场" min-width="150"></el-table-column>
      <el-table-column prop="cardName" label="被投诉员工" min-width="150">
        <template slot-scope="scope">
          {{scope.row.cardName||'未投诉指定员工'}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="投诉时间" width="140"></el-table-column>
      <el-table-column prop="typeName" label="投诉类型" min-width="140"></el-table-column>
      <el-table-column prop="content" label="客户诉求" min-width="200"></el-table-column>
      <el-table-column prop="status" label="回访状态" width="110">
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.returnVisitId" type="info">未生成回访</el-tag>
          <el-tag v-if="scope.row.returnVisitId&&scope.row.returnVisitStatus==20" type="success">回访已解决</el-tag>
          <el-tag v-if="scope.row.returnVisitId&&scope.row.returnVisitStatus==10" type="warning">待回访</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="是否已解决" width="100">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.status==1" type="success">已解决</el-tag>
          <el-tag size="medium" v-else type="danger">未解决</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <template v-if="scope.row.status==0">
            <el-button type="text" @click="outbound(scope.row)">外呼</el-button>
            <el-button type="text" @click="showHandleComplaint(scope.row.id)">添加跟进</el-button>
            <el-button type="text" v-if="!scope.row.returnVisitId" @click="createRevisitOrder(scope.row)">生成回访
            </el-button>
          </template>
          <el-button type="text" @click="selectComplaintHandleRecord(scope.row.id)">处理记录</el-button>
        </template>
      </el-table-column>
    </cui-table>

    <el-dialog :title="dialogData.title" :visible.sync="dialogData.show" :width="dialogData.width" :modal="false" :close-on-click-modal="false">
      <el-form label-width="100px" v-if="dialogData.type=='deal'">
        <el-form-item label="跟进记录">
          <el-input v-model="dealInfo.replyContent" type="textarea" :rows="5" placeholder="请输入本次跟进的相关记录"></el-input>
        </el-form-item>
        <el-form-item label="是否解决">
          <el-radio-group v-model="dealInfo.status">
            <el-radio label="0" border>未解决</el-radio>
            <el-radio label="1" border>已解决</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否有效" v-if="dealInfo.status=='1'">
          <el-radio-group v-model="dealInfo.isValid">
            <el-radio label="0" border>无效投诉</el-radio>
            <el-radio label="1" border>有效投诉</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleComplaint()">提 交</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="historyData" height="350" border style="width: 100%" v-else>
        <el-table-column prop="handlerName" label="跟进客服" width="100" align="center"></el-table-column>
        <el-table-column prop="createTime" label="跟进时间" width="150" align="center"></el-table-column>
        <el-table-column prop="replyContent" label="跟进记录" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "matterComplaint",
  title: "意见投诉",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        username: "",
        mobile: "",
        complainTypeId: "",
        clientType: "",
        status: "",
      },
      complainTypeData: [],
      dialogData: {
        title: "",
        show: false,
        width: "500px",
        type: "deal",
      },
      dealInfo: {
        complainId: "",
        replyContent: "",
        status: "0",
        isValid: "0",
      },
      historyData: [],
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.status = this.$route.query.status;
    }
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
    this.selectItemList();
  },
  methods: {
    outbound(item) {
      this.showHandleComplaint(item.id);
      this.$root.$emit("outbound", item.mobile);
    },
    showHandleComplaint(complainId) {
      this.dialogData.title = "添加投诉处理记录";
      this.dialogData.show = true;
      this.dialogData.width = "500px";
      this.dialogData.type = "deal";
      this.dealInfo.complainId = complainId;
    },
    handleComplaint() {
      this.$http.post(this.$api.handleComplaint, this.dealInfo, (res) => {
        Object.assign(this.$data.dialogData, this.$options.data().dialogData);
        Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
        this.$message.success("投诉跟进处理记录添加成功！");
        this.$refs.table.reloadData();
      });
    },
    selectComplaintHandleRecord(complaintId) {
      this.$http.post(
        this.$api.selectComplaintHandleRecord,
        { complaintId },
        (res) => {
          this.historyData = res.list;
          this.dialogData.title = "投诉处理历史记录";
          this.dialogData.show = true;
          this.dialogData.width = "700px";
          this.dialogData.type = "history";
        }
      );
    },
    createRevisitOrder(item) {
      item.orderType = "20";
      this.$root.$emit("showWorkorderDialog", {
        type: "revisit",
        data: item,
        callback: () => {
          this.$refs.table.reloadData();
        },
      });
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "complain",
        },
        (res) => {
          this.complainTypeData = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>