export default [{
    filterName: 'userStatus',
    filterFunctrion: (value) => {
        let map = {
            0: '正常',
            1: '禁用',
        }
        return map[value]
    }
}, {
    filterName: 'userType',
    filterFunctrion: (value) => {
        let map = {
            0: '超级管理员',
            1: '禁用',
        }
        return map[value]
    }
}, {
    filterName: 'parkType',
    filterFunctrion: (value) => {
        let map = {
            1: '平面停车场',
            2: '遥控停车场',
            3: '机械停车场',
            4: '立体停车场',
            5: '高位视频+地锁',
            6: '低位视频+地锁',
            7: '地磁+PDA',
            8: '纯PDA',
            9: '纯低位视频',
            10: '纯高位视频',
        }
        return map[value]
    }
}, {
    filterName: 'cooperationType',
    filterFunctrion: (value) => {
        let map = {
            0: '自营停车场',
            1: '第三方停车场',
        }
        return map[value]
    }
}, {
    filterName: 'isLinkageType',
    filterFunctrion: (value) => {
        let map = {
            0: '未开启',
            1: '已开启',
        }
        return map[value]
    }
}, {
    filterName: 'parkStatus',
    filterFunctrion: (value) => {
        let map = {
            0: '正常启用',
            1: '暂停使用',
        }
        return map[value]
    }
}, {
    filterName: 'bannerType',
    filterFunctrion: (value) => {
        let map = {
            1: '轮播图',
            2: '热门功能',
        }
        return map[value]
    }
}, {
    filterName: 'bannerStatus',
    filterFunctrion: (value) => {
        let map = {
            0: '展示',
            1: '隐藏',
        }
        return map[value]
    }
}, {
    filterName: 'newsStatus',
    filterFunctrion: (value) => {
        let map = {
            0: '展示',
            1: '隐藏',
        }
        return map[value]
    }
}, {
    filterName: 'payType',
    filterFunctrion: (value) => {
        let map = {
            1: '支付宝',
            2: '微信',
            3: '余额',
            4: '银行卡',
            5: '路侧现金收费',
            6: '道闸现金收费',
            7: '道闸追缴支付',
            8: 'VIP月卡',
        }
        return map[value]
    }
}, {
    filterName: 'mapSelectTypeStatus',
    filterFunctrion: (value) => {
        let map = {
            'marker': '选择单点',
            'polyline': '选择折线',
            'polygon': '选择多边形',
            'rectangle': '选择矩形',
            'circle': '选择圆形',
        }
        return map[value]
    }
}, {
    filterName: 'strToArr',
    filterFunctrion: (value) => {
        if (value) {
            let arr = value.split(',')
            return arr
        } else {
            return []
        }
    }
}, {
    filterName: 'totalHours',
    filterFunctrion: (value) => {
        if (value) {
            let { enterTime, leaveTime } = value
            if (leaveTime) {
                leaveTime = new Date(leaveTime)
            } else {
                leaveTime = new Date()
            }
            if (enterTime) {
                enterTime = new Date(enterTime)
            } else {
                enterTime = new Date()
            }
            const diff = Math.abs(leaveTime - enterTime);
            const day = Math.floor(diff / 1000 / 60 / 60 / 24);
            const hours = Math.floor((diff / 1000 / 60 / 60) % 24);
            const minutes = Math.floor((diff / 1000 / 60) % 60);
            if (day > 0) {
                return `${day}天${hours}小时${minutes}分钟`
            } else if (hours > 0) {
                return `${hours}小时${minutes}分钟`
            } else {
                return `${minutes}分钟`
            }
        } else {
            return '0小时'
        }
    }
}]