<template>
  <div>
    <el-form inline>
      <el-form-item label="通道名称">
        <el-input v-model="searchData.channelName" placeholder="请输入通道名称"></el-input>
      </el-form-item>
      <el-form-item label="通道类型">
        <el-select v-model="searchData.type" placeholder="请选择" filterable>
          <el-option label="全部" value=""></el-option>
          <el-option label="进口" value="1"></el-option>
          <el-option label="出口" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属停车场">
        <el-select v-model="searchData.parkId" filterable remote placeholder="请输入关键词搜索停车场" :remote-method="getParkAreaList">
          <el-option v-for="(item,key) in parkingAreaList" :key="key" :label="item.parkingName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.table.reloadData()">查 询</el-button>
      </el-form-item>
    </el-form>

    <cui-table :url="$api.selectChannelList" :data="searchData" ref="table">
      <el-table-column fixed type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="id" label="编号" min-width="160"></el-table-column>
      <el-table-column prop="channelName" label="通道名称" min-width="150"></el-table-column>
      <el-table-column label="通道类型" width="100">
        <template slot-scope="scope">
          {{scope.row.type=='1'?'进口':'出口'}}
        </template>
      </el-table-column>
      <el-table-column prop="parkingName" label="关联停车场" min-width="170"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" @click="openCloudGateByLocation(scope.row.id)">开闸</el-button>
          <el-button type="text" @click="closeCloudGateByLocation(scope.row.id)">关闸</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "createOpenGate",
  title: "紧急开闸指令",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        channelName: "",
        type: "",
        parkId: "",
      },
      parkingAreaList: [],
    };
  },
  methods: {
    openCloudGateByLocation(location) {
      this.$http.get(
        this.$api.openCloudGateByLocation,
        { location },
        (res) => {
          this.$message.success("开闸指令下发成功！");
        }
      );
    },
    closeCloudGateByLocation(location) {
      this.$http.get(
        this.$api.closeCloudGateByLocation,
        { location },
        (res) => {
          this.$message.success("关闸指令下发成功！");
        }
      );
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
  },
};
</script>

<style>
</style>