<template>
  <div>
    <el-form inline>
      <el-form-item label="车位号">
        <el-input v-model="searchData.spaceNum" placeholder="请输入车位号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$refs.table.reloadData()">查 询</el-button>
      </el-form-item>
    </el-form>

    <cui-table :url="$api.selectLockSeatList" :data="searchData" ref="table">
      <el-table-column fixed type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="deviceName" label="设备名"></el-table-column>
      <el-table-column prop="parkingName" label="所属停车场"></el-table-column>
      <el-table-column prop="seatNum" label="关联车位号"></el-table-column>
      <el-table-column label="操作" width="90">
        <template slot-scope="scope">
          <el-button type="text" @click="openCloseLockById(scope.row.id,1)">升锁</el-button>
          <el-button type="text" @click="openCloseLockById(scope.row.id,2)">降锁</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "createOpen",
  title: "紧急降锁指令",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        spaceNum: "",
      },
    };
  },
  methods: {
    openCloseLockById(id, type) {
      this.$http.post(this.$api.openCloseLockById, { id, type }, (res) => {
        this.$message.success("指令下发成功！");
      });
    },
  },
};
</script>

<style>
</style>