<template>
  <div>
    客服工作台
  </div>
</template>

<script>
export default {
  name: "workBranch",
  title: "客服工作台",
};
</script>

<style>
</style>