<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="姓名">
          <el-input v-model="searchData.userName" placeholder="输入用户姓名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="输入订单手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="车牌">
          <el-input v-model="searchData.plate" placeholder="输入车牌号查找"></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-input v-model="searchData.parkName" placeholder="请输入停车场"></el-input>
        </el-form-item>
        <el-form-item label="停车类型">
          <el-select v-model="searchData.parkType">
            <el-option label="全部" value=""></el-option>
            <el-option :label="item.lable" :value="item.value" v-for="(item) in $baseData.parkingType" :key="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="searchData.datetimeRange" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">查询</el-button>
          <el-button @click="Object.assign($data.searchData, $options.data().searchData)">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectArrearsOrPayOrderList" :data="searchData" ref="table">
      <el-table-column fixed prop="id" label="订单ID" width="160"></el-table-column>
      <el-table-column prop="plate" label="车牌号" width="100"></el-table-column>
      <el-table-column prop="userName" label="用户姓名"></el-table-column>
      <el-table-column prop="userMobile" label="用户手机号" width="150"></el-table-column>
      <el-table-column label="订单类型" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.seatNum" type="info">路侧订单</el-tag>
          <el-tag v-else type="success">道闸订单</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="parkingName" label="停车场" min-width="150"></el-table-column>
      <el-table-column prop="seatNum" label="车位编号" min-width="130"></el-table-column>
      <el-table-column label="入场时间" min-width="250">
        <template slot-scope="scope">
          <span>{{scope.row.enterTime}}&nbsp;&nbsp;</span>
          <el-button type="text" :disabled="scope.row.inPic?false:true" @click="viewImg(scope.row.inPic)">
            {{scope.row.inPic?"查看图片":"暂无图片"}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="出场时间" min-width="250">
        <template slot-scope="scope">
          <span>{{scope.row.leaveTime}}&nbsp;&nbsp;</span>
          <el-button type="text" :disabled="scope.row.outPic?false:true" @click="viewImg(scope.row.outPic)">
            {{scope.row.outPic?"查看图片":"暂无图片"}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="支付金额"></el-table-column>
      <el-table-column prop="arrearsAmount" label="欠费金额"></el-table-column>
    </cui-table>

  </div>
</template>

<script>
export default {
  name: "orderArrears",
  title: "欠费订单",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        userName: "",
        mobile: "",
        plate: "",
        type: "1",
        orderType: "",
        parkName: "",
        parkType: "",
        datetimeRange: [],
        startTime: "",
        endTime: "",
      },
    };
  },
  watch: {
    "searchData.datetimeRange": function (value) {
      if (value && value.length == 2) {
        this.searchData.startTime = value[0];
        this.searchData.endTime = value[1];
      } else {
        this.searchData.startTime = "";
        this.searchData.endTime = "";
      }
    },
  },
  methods: {
    viewImg(url) {
      this.$alert(
        `<img style='width:700px;height:auto;' src="${url}">`,
        "预览",
        {
          dangerouslyUseHTMLString: true,
          customClass: "alertBox",
          callback: (action) => {},
        }
      );
    },
  },
};
</script>

<style>
</style>