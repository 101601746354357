<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="用户名：">
          <el-input v-model="searchData.username" placeholder="请输入用户名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="咨询类型">
          <el-select v-model="searchData.consultationType" placeholder="请选择">
            <el-option v-for="item in consultTypeData" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回访状态">
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option value="" label="全部"></el-option>
            <el-option value="10" label="待回访"></el-option>
            <el-option value="20" label="已解决"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
          <el-button @click="Object.assign($data.searchData,$options.data().searchData)">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.selectHistoryConsultation" :data="searchData" ref="table" width="200">
      <el-table-column label="序号" type="index" min-width="60"></el-table-column>
      <el-table-column prop="receptionistId" label="客服Id" width="150"></el-table-column>
      <el-table-column prop="username" label="咨询用户" width="120">
        <template slot-scope="scope">{{scope.row.username?scope.row.username:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
      <el-table-column prop="consultationName" label="咨询类型" min-width="120"></el-table-column>
      <el-table-column prop="createTime" label="咨询时间" width="140"></el-table-column>
      <el-table-column prop="content" label="咨询内容" min-width="300"></el-table-column>
      <el-table-column prop="status" label="回访状态" min-width="120">
        <template slot-scope="scope">
          <template v-if="scope.row.returnVisitId">
            <el-tag v-if="scope.row.returnVisitStatus==20" type="success">回访已解决</el-tag>
            <el-tag v-if="scope.row.returnVisitStatus==10" type="warning">待回访</el-tag>
          </template>
          <template v-else>
            <el-tag type="info">未生成回访单</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="是否已解决" min-width="100">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.status=='10'" type="danger">未解决</el-tag>
          <el-tag size="medium" v-else type="success">已解决</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="220">
        <template slot-scope="scope">
          <template v-if="scope.row.status=='10'">
            <el-button type="text" @click="outbound(scope.row)">外呼</el-button>
            <el-button type="text" @click="showReplyConsultation(scope.row.id)">添加跟进</el-button>
            <el-button type="text" v-if="!scope.row.returnVisitId" @click="createRevisitOrder(scope.row)">生成回访
            </el-button>
          </template>
          <el-button type="text" @click="selectConsultHandleRecord(scope.row.id)">详情</el-button>
        </template>
      </el-table-column>
    </cui-table>

    <el-dialog :title="dialogData.title" :visible.sync="dialogData.show" :width="dialogData.width" :modal="false" :close-on-click-modal="false">
      <el-form label-width="100px" v-if="dialogData.type=='deal'">
        <el-form-item label="跟进记录">
          <el-input v-model="dealInfo.replyContent" type="textarea" :rows="5" placeholder="请输入本次跟进的相关记录"></el-input>
        </el-form-item>
        <el-form-item label="是否解决">
          <el-radio-group v-model="dealInfo.status">
            <el-radio label="10" border>未解决</el-radio>
            <el-radio label="20" border>已解决</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="replyConsultation()">提 交</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="historyData" height="350" border style="width: 100%" v-else>
        <el-table-column prop="handlerName" label="跟进客服" width="100" align="center"></el-table-column>
        <el-table-column prop="createTime" label="跟进时间" width="150" align="center"></el-table-column>
        <el-table-column prop="replyContent" label="跟进记录" align="center"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "matterConsult",
  title: "来电咨询",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        username: "",
        mobile: "",
        consultationType: "",
        status: "",
      },
      consultTypeData: [],
      dialogData: {
        title: "",
        show: false,
        width: "500px",
        type: "deal",
      },
      dealInfo: {
        complainId: "",
        replyContent: "",
        status: "0",
      },
      historyData: [],
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.status = this.$route.query.status;
    }
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
    this.selectItemList();
  },
  methods: {
    outbound(item) {
      this.showReplyConsultation(item.id);
      this.$root.$emit("outbound", item.mobile);
    },
    showReplyConsultation(consultId) {
      this.dialogData.title = "添加咨询处理记录";
      this.dialogData.show = true;
      this.dialogData.width = "500px";
      this.dialogData.type = "deal";
      this.dealInfo.consultationId = consultId;
    },
    replyConsultation() {
      this.dealInfo.handlerType = 20;
      this.$http.post(this.$api.replyConsultation, this.dealInfo, (res) => {
        Object.assign(this.$data.dialogData, this.$options.data().dialogData);
        Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
        this.$message.success("咨询回复成功！");
        this.$refs.table.reloadData();
      });
    },
    createRevisitOrder(item) {
      item.orderType = "30";
      this.$root.$emit("showWorkorderDialog", {
        type: "revisit",
        data: item,
        callback: () => {
          this.$refs.table.reloadData();
        },
      });
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "consultation",
        },
        (res) => {
          this.consultTypeData = res.list;
        }
      );
    },
    selectConsultHandleRecord(consultationId) {
      this.$http.post(
        this.$api.selectConsultationReplyRecord,
        {
          consultationId: consultationId,
          type: "consultation",
          pageSize: 99999,
          currentPage: 1,
        },
        (res) => {
          this.historyData = res.list;
          this.dialogData.title = "咨询处理历史记录";
          this.dialogData.show = true;
          this.dialogData.width = "700px";
          this.dialogData.type = "history";
        }
      );
    },
  },
};
</script>

<style>
</style>