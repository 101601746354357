import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    caller: ''
  },
  mutations: {
    setCallerInfo(state, caller) {
      state.caller = caller
    }
  },
  actions: {
  },
  modules: {
    userInfo
  }
})
