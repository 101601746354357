<template>
  <div class="plate" style="display:inline-block;" @click.stop="dialogVisible=true">
    <slot name="enter">
      <div class="enter">车牌键盘</div>
    </slot>
    <el-dialog title="系统车牌键盘" :visible.sync="dialogVisible" width="1000px" append-to-body :before-close="handleClose">
      <div class="input-box">
        <p class="input" v-for="(it,key) in plate" :key="key">{{it}}</p>
      </div>
      <div class="letter">
        <el-button class="item" :disabled="plate.length==0" @click="inputWord(it.key)" v-for="(it,key) in letter"
          :key="key" size="medium">
          {{it.key}}</el-button>
      </div>
      <div class="bottm">
        <div class="province">
          <el-button class="item" :disabled="plate.length!=0" @click="inputWord(it.key)" v-for="(it,key) in province"
            :key="key" size="medium">
            {{it.key}}</el-button>
          <el-button class="item" type="primary" plain size="medium" @click="deleteWord">删除</el-button>
          <el-button class="item" type="primary" plain size="medium" @click="clearWord">清空</el-button>
        </div>
        <div class="number">
          <el-button class="item" :disabled="plate.length<2" @click="inputWord(it.key)" v-for="(it,key) in number"
            :key="key" size="medium">
            {{it.key}}</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cui-plate",
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisible: false,
      plate: "",
      province: [
        { key: "京" },
        { key: "津" },
        { key: "沪" },
        { key: "渝" },
        { key: "冀" },
        { key: "晋" },
        { key: "辽" },
        { key: "吉" },
        { key: "黑" },
        { key: "闽" },
        { key: "苏" },
        { key: "浙" },
        { key: "皖" },
        { key: "鄂" },
        { key: "湘" },
        { key: "粤" },
        { key: "琼" },
        { key: "赣" },
        { key: "鲁" },
        { key: "豫" },
        { key: "川" },
        { key: "贵" },
        { key: "云" },
        { key: "陕" },
        { key: "甘" },
        { key: "青" },
        { key: "藏" },
        { key: "桂" },
        { key: "蒙" },
        { key: "宁" },
        { key: "新" },
        { key: "港" },
        { key: "澳" },
        { key: "警" },
        { key: "学" },
        { key: "WJ" },
      ],
      letter: [
        { key: "A" },
        { key: "B" },
        { key: "C" },
        { key: "D" },
        { key: "E" },
        { key: "F" },
        { key: "G" },
        { key: "H" },
        { key: "J" },
        { key: "K" },
        { key: "L" },
        { key: "M" },
        { key: "N" },
        { key: "P" },
        { key: "Q" },
        { key: "R" },
        { key: "S" },
        { key: "T" },
        { key: "U" },
        { key: "V" },
        { key: "W" },
        { key: "X" },
        { key: "Y" },
        { key: "Z" },
      ],
      number: [
        { key: "1" },
        { key: "2" },
        { key: "3" },
        { key: "4" },
        { key: "5" },
        { key: "6" },
        { key: "7" },
        { key: "8" },
        { key: "9" },
        { key: "0" },
      ],
    };
  },
  methods: {
    inputWord(value) {
      if (this.plate.length < 8) {
        this.plate += value.toString();
      } else {
        this.$message.info("车牌号码最多8位！");
      }
    },
    deleteWord() {
      this.plate = this.plate.slice(0, this.plate.length - 1);
    },
    clearWord() {
      this.plate = "";
    },
    handleClose() {
      this.plate = "";
      this.dialogVisible = false;
    },
    confirm() {
      this.$emit("input", this.plate);
      this.plate = "";
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.enter {
  color: #409eff;
  padding: 0px 12px;
  cursor: pointer;
}
.input-box {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.input {
  font-size: 32px;
  line-height: 1em;
  font-weight: bold;
  color: #333;
  margin: 0 5px;
}
.input:nth-child(2)::after {
  content: "·";
  margin-left: 5px;
}
.letter {
  text-align: center;
  margin-bottom: 20px;
}
.item {
  margin-bottom: 10px;
  margin-left: 10px;
}
.bottm {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.bottm .province {
  flex: 3;
  margin-right: 5px;
}
.bottm .number {
  flex: 1;
  margin-left: 5px;
}
</style>