<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="姓名">
          <el-input v-model="searchData.userName" placeholder="请输入用户名或员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-select v-model="searchData.parkId" clearable filterable remote reserve-keyword placeholder="请输入关键词查询停车场"
            :remote-method="getParkAreaList">
            <el-option v-for="item in parkingAreaList" :key="item.id" :label="item.parkingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约状态">
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option v-for="item in statusList" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.selectAppointmentOrderList" :data="searchData" ref="table" width="200">
      <el-table-column fixed type="index" label="序号" width="70"></el-table-column>
      <el-table-column prop="mobile" label="用户手机号" min-width="40"></el-table-column>
      <el-table-column prop="username" label="用户名" min-width="30"></el-table-column>
      <el-table-column prop="parkName" label="停车场名称" min-width="60"></el-table-column>
      <el-table-column prop="seatNum" label="车位编号" min-width="70"></el-table-column>
      <el-table-column prop="appointmentTime" label="预约时间" min-width="60"></el-table-column>
      <el-table-column prop="appointmentEndTime" label="失效时间" min-width="60"></el-table-column>
      <el-table-column prop="status" label="订单状态" min-width="40">
        <template slot-scope="scope">
          <span v-if="scope.row.status==3" class="el-tag">完成预约</span>
          <span v-if="scope.row.status==2" class="el-tag el-tag--info">取消预约</span>
          <span v-if="scope.row.status==1" class="el-tag el-tag--success">预约中</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="loadUrgeOrderList(scope.row.userId)">取消预约</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "orderConllection",
  title: "待催收订单",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        userName: "",
        mobile: "",
        parkId: "",
        status: "",
      },
      parkingAreaList: [],
      statusList: [
        { value: "全部", id: "" },
        { value: "完成预约", id: "3" },
        { value: "取消预约", id: "2" },
        { value: "预约中", id: "2" },
      ],
    };
  },
  methods: {
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
  },
};
</script>

<style>
</style>