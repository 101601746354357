<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="来电号码">
        <el-input v-model="repairData.mobile" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="来电姓名">
        <el-input v-model="repairData.userName" placeholder="请输入来电人姓名"></el-input>
      </el-form-item>
      <el-form-item label="关联车牌">
        <el-input v-model="repairData.plate" placeholder="请输入关联车牌"></el-input>
      </el-form-item>
      <el-form-item label="维修停车场">
        <el-select v-model="repairData.parkId" filterable remote placeholder="请输入关键词搜索停车场"
          :remote-method="getParkAreaList">
          <el-option v-for="(item,key) in parkingAreaList" :key="key" :label="item.parkingName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车位编号">
        <el-input placeholder="请输入待维修的车位编号" v-model="repairData.seatNum"></el-input>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-select v-model="repairData.deviceType">
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障类型">
        <el-select v-model="repairData.dictionaryId">
          <el-option v-for="item in brokenTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障描述">
        <el-input type="textarea" v-model="repairData.description" :rows="5" placeholder="请输入工单描述">
        </el-input>
      </el-form-item>
      <el-form-item label="加急处理">
        <el-radio-group v-model="repairData.weight" size="small">
          <el-radio :label="0" border>不加急</el-radio>
          <el-radio :label="10" border>加急处理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createRepair">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createRecordRepair",
  title: "新建报修记录",
  data() {
    return {
      repairData: {
        mobile: "",
        userName: "",
        plate: "",
        parkId: "",
        seatNum: "",
        weight: 0,
        dictionaryId: "",
        deviceType: "",
        originType: undefined,
        clientType: 60,
        logsType: 0,
      },
      parkingAreaList: [],
      deviceTypeList: [],
      brokenTypeList: [],
    };
  },
  created() {
    this.repairData.mobile = this.$store.state.caller || "";
    this.selectItemList();
  },
  methods: {
    createRepair() {
      this.$http.post(this.$api.insertBrokenRecord, this.repairData, (res) => {
        this.$message.success("维修工单创建成功！");
        Object.assign(this.$data.repairData, this.$options.data().repairData);
        this.$root.$emit("closeWorkorderDialog");
      });
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "device_type",
        },
        (res) => {
          this.deviceTypeList = res.list;
        }
      );
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "broken",
        },
        (res) => {
          this.brokenTypeList = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>