<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="来电号码">
        <el-input v-model="pollingData.mobile" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="指派人员">
        <el-select v-model="pollingData.patrolman">
          <el-option v-for="(item,key) in parkWorkerList" :key="key"
            :label="`${item.nickName}[${item.mobile}]-${item.isOnLine=='1'?'在线':'离线'}`" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-select v-model="pollingData.deviceType">
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障类型">
        <el-select v-model="pollingData.typeId">
          <el-option v-for="item in brokenTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障描述">
        <el-input type="textarea" v-model="pollingData.description" :rows="5" placeholder="请输入工单描述">
        </el-input>
      </el-form-item>
      <el-form-item label="加急处理">
        <el-radio-group v-model="pollingData.weight" size="small">
          <el-radio :label="0" border>不加急</el-radio>
          <el-radio :label="10" border>加急处理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createPolling">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createWorkorderPolling",
  title: "新建巡检工单",
  props: ["data","callback"],
  data() {
    return {
      pollingData: {
        mobile: "",
        parkId: "",
        seatNum: "",
        patrolman: "",
        weight: 0,
        typeId: "",
        deviceType: "",
        originType: "",
        clientType: 60,
        description: "",
        logsType: 0,
        logsId:'',
      },
      parkWorkerList: [],
      deviceTypeList: [],
      brokenTypeList: [],
    };
  },
  watch: {
    data: function (value) {
      this.pollingData.mobile = value.mobile;
      this.pollingData.parkId = value.parkId;
      this.pollingData.seatNum = value.seatNum;
      this.pollingData.originType = value.originType;
      this.pollingData.deviceType = value.deviceType;
      this.pollingData.typeId = value.brokenType;
      this.pollingData.description = value.description;
      this.pollingData.logsId = value.id;
      this.getParkWorkerList(value.parkId);
    },
  },
  created() {
    this.pollingData.mobile = this.$store.state.caller || "";
    this.pollingData.mobile = this.data.mobile;
    this.pollingData.parkId = this.data.parkId;
    this.pollingData.seatNum = this.data.seatNum;
    this.pollingData.originType = this.data.originType;
    this.pollingData.deviceType = this.data.deviceType;
    this.pollingData.typeId = this.data.brokenType;
      this.pollingData.description = this.data.description;
    this.pollingData.logsId = this.data.id;
    this.selectItemList();
    this.getParkWorkerList(this.data.parkId);
  },
  methods: {
    createPolling() {
      this.$http.post(this.$api.insertDeviceRepair, this.pollingData, (res) => {
        this.$root.$emit("closeWorkorderDialog");
        this.$message.success("巡检工单创建成功！");
        Object.assign(this.$data.pollingData, this.$options.data().pollingData);
        this.callback();
      });
    },
    getParkWorkerList(parkId) {
      this.$http.post(
        this.$api.selectEmpList,
        {
          currentPage: 1,
          pageSize: 99999,
          parkId: parkId,
          roleType: "3",
        },
        (res) => {
          this.parkWorkerList = res.list;
        }
      );
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "device_type",
        },
        (res) => {
          this.deviceTypeList = res.list;
        }
      );
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "broken",
        },
        (res) => {
          this.brokenTypeList = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>