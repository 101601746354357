<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="来电号码">
        <el-input v-model="complaintData.mobile" placeholder="请输入来电号码"></el-input>
      </el-form-item>
      <el-form-item label="来电姓名">
        <el-input v-model="complaintData.userName" placeholder="请输入来电人姓名"></el-input>
      </el-form-item>
      <el-form-item label="关联车牌">
        <el-input v-model="complaintData.plate" placeholder="请输入关联车牌"></el-input>
      </el-form-item>
      <el-form-item label="关联停车场">
        <el-select v-model="complaintData.parkId" filterable remote placeholder="请输入关键词搜索停车场" :remote-method="getParkAreaList">
          <el-option v-for="(item,key) in parkingAreaList" :key="key" :label="item.parkingName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联员工">
        <el-select v-model="complaintData.cardNumber" filterable remote placeholder="请输入员工工号查询" :remote-method="getParkWorkerList">
          <el-option v-for="(item,key) in parkWorkerList" :key="key" :label="`${item.nickName}[${item.mobile}]-${item.isOnLine=='1'?'在线':'离线'}`" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投诉类型">
        <el-select v-model="complaintData.typeId">
          <el-option v-for="item in complaintTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投诉内容">
        <el-input v-model="complaintData.content" type="textarea" :rows="5" placeholder="请输入内容">
        </el-input>
      </el-form-item>
      <el-form-item label="回复内容">
        <el-input v-model="complaintData.reply" type="textarea" :rows="5" placeholder="请输入内容">
        </el-input>
      </el-form-item>
      <el-form-item label="是否解决">
        <el-radio-group v-model="complaintData.status">
          <el-radio label="0" border>未解决</el-radio>
          <el-radio label="1" border>已解决</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否有效" v-if="complaintData.status=='1'">
        <el-radio-group v-model="complaintData.isValid">
          <el-radio label="0" border>无效投诉</el-radio>
          <el-radio label="1" border>有效投诉</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="insertComplaint" type="primary">提交</el-button>
        <el-button type="danger" @click="Object.assign(this.$data.complaintData,this.$options.data().complaintData)">清空
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createRecordComplaint",
  title: "新建来电投诉记录",
  data() {
    return {
      complaintData: {
        parkId: "",
        mobile: "",
        userName: "",
        plate: "",
        typeId: "",
        content: "",
        reply: "",
        status: 10,
        isValid: "0",
      },
      complaintTypeList: [],
      parkingAreaList: [],
      parkWorkerList: [],
    };
  },
  created() {
    this.complaintData.mobile = this.$store.state.caller || "";
    this.selectItemList();
  },
  methods: {
    insertComplaint() {
      this.$http.post(this.$api.insertComplaint, this.complaintData, (res) => {
        this.$message.success("来电投诉记录添加成功！");
        Object.assign(
          this.$data.complaintData,
          this.$options.data().complaintData
        );
        this.$root.$emit("closeWorkorderDialog");
      });
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "complain",
        },
        (res) => {
          this.complaintTypeList = res.list;
        }
      );
    },
    getParkWorkerList(query) {
      if (this.complaintData.parkId) {
        this.$http.post(
          this.$api.selectEmpList,
          {
            currentPage: 1,
            pageSize: 10,
            cardNumber: query || "",
          },
          (res) => {
            this.parkWorkerList = res.list;
          }
        );
      } else {
        this.$message.info("请先选择要投诉的停车场！");
      }
    },
  },
};
</script>

<style>
</style>