<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="订单号">
        <el-input readonly v-model="addAbnormal.orderErrorId"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input v-model="addAbnormal.mobile"></el-input>
      </el-form-item>
      <el-form-item label="停车场">
        <el-input readonly v-model="addAbnormal.parkingName"></el-input>
      </el-form-item>
      <el-form-item label="车位号">
        <el-input readonly v-model="addAbnormal.seatNum"></el-input>
      </el-form-item>
      <el-form-item label="车牌号">
        <el-input readonly v-model="addAbnormal.licencePlateId"></el-input>
      </el-form-item>
      <el-form-item :required="true" label="异常类型">
        <el-select v-model="addAbnormal.errorType">
          <el-option v-for="item in orderErrorTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :required="true" label="所在地点">
        <el-select v-model="location">
          <el-option label="停车场入口" value="停车场入口"></el-option>
          <el-option label="停车场出口" value="停车场出口"></el-option>
          <el-option label="车辆已出场" value="车辆已出场"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :required="true" label="异常描述">
        <el-input v-model="addAbnormal.description" type="textarea" :rows="5" placeholder="请输入内容">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" @click="addAbnormalOrderCallRecord()" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createRecordOrder",
  title: "生成异常订单记录",
  props: ["data","callback"],
  data() {
    return {
      addAbnormal: {
        errorType: "",
        seatNum: "",
        mobile:'',
        licencePlateId: "",
        orderErrorId: "",
        description: "",
      },
      parkType: "",
      location: "车辆已出场",
      withoutPlate: false,
      orderErrorTypeList: [],
    };
  },
  watch: {
    data: function () {
      this.addAbnormal.licencePlateId = this.data.plate;
      this.addAbnormal.mobile = this.data.userMobile;
      this.addAbnormal.orderErrorId = this.data.id;
      this.addAbnormal.parkId = this.data.parkId;
      this.addAbnormal.parkingName = this.data.parkingName;
      this.addAbnormal.seatNum = this.data.seatNum;
    },
  },
  created() {
    this.addAbnormal.mobile = this.$store.state.caller || "";
    this.addAbnormal.licencePlateId = this.data.plate;
    this.addAbnormal.mobile = this.data.userMobile;
    this.addAbnormal.orderErrorId = this.data.id;
    this.addAbnormal.parkId = this.data.parkId;
    this.addAbnormal.parkingName = this.data.parkingName;
    this.addAbnormal.seatNum = this.data.seatNum;
    this.selectItemList();
  },
  methods: {
    addAbnormalOrderCallRecord() {
      this.$http.post(
        this.$api.addAbnormalOrderCallRecord,
        this.addAbnormal,
        (res) => {
          this.$message.success("异常订单记录创建成功！");
          Object.assign(
            this.$data.addAbnormal,
            this.$options.data().addAbnormal
          );
          this.$root.$emit("closeWorkorderDialog");
          this.callback()
        }
      );
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "order_error",
        },
        (res) => {
          this.orderErrorTypeList = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>