<template>
  <div style="display:inline-block;" @click.stop="dialogVisible=true">
    <slot name="upload">
      <div class="upload">点击上传</div>
    </slot>
    <el-dialog title="图片上传" :visible.sync="dialogVisible" width="30%" append-to-body :before-close="beforeClose">
      <el-upload ref="upload" action="#" accept="image/*" :multiple="limit>1" :limit="limit" list-type="picture-card"
        :auto-upload="false" :on-change="selectChange">
        <span>选取图片</span>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="beforeClose">取 消</el-button>
        <el-button type="primary" @click="upload" :disabled="fileList.length==0">上 传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cui-upload",
  data() {
    return {
      dialogVisible: false,
      fileList: [],
    };
  },
  props: {
    value: {
      type: String | Array,
    },
    limit: {
      type: Number,
      default: 1,
    },
    uploadFunction: {
      required: true,
      type: Function,
    },
  },
  methods: {
    beforeClose() {
      this.$refs.upload.clearFiles();
      this.dialogVisible = false;
    },
    selectChange(file, fileList) {
      this.fileList = fileList;
    },
    upload() {
      let data = [];
      this.fileList.forEach((e) => {
        data.push(e.raw);
      });
      this.globalUploadFunction(data).then((res) => {
        let value;
        if (this.limit > 1) {
          value = [...this.value];
          res.forEach((e) => {
            value.push(e.fileUrl);
          });
        } else {
          value = res[0].fileUrl;
        }
        this.$emit("input", value);
        this.beforeClose();
      });
    },
  },
};
</script>

<style lang="css" scoped>
.upload {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  line-height: 100px;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
}
</style>