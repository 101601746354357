import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'cgan-axios'
import axiosConfig from './config/cgan-axios'
import apiConfig from './config/api'
import components from './components/index'
import filters from './config/filter'
import mixin from './config/mixin'
import baseData from './config/baseData'

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small', zIndex: 1500 })
Vue.use(axios, axiosConfig)
Vue.use(apiConfig)
Vue.use(components)
Vue.use(baseData)

//全局混入
Vue.mixin(mixin)

//全局过滤
filters.forEach(e => {
  Vue.filter(e.filterName, value => {
    let filterValue = e.filterFunctrion(value)
    return filterValue
  })
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
