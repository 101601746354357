<template>
  <div>
    <div class="card-box">
      <el-card class="card">
        <div slot="header">
          <p>来电快捷处理</p>
        </div>
        <div class="handle">
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'consultation'})">
            <i class="el-icon-mobile"></i>
            <p>添加咨询记录</p>
          </div>
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'complaint'})">
            <i class="el-icon-chat-dot-square"></i>
            <p>添加投诉记录</p>
          </div>
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'repairs'})">
            <i class="el-icon-document-checked"></i>
            <p>添加报修记录</p>
          </div>
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'message'})">
            <i class="el-icon-thumb"></i>
            <p>推送消息</p>
          </div>
          <div class="handle-item" @click="$router.push('/order/error')">
            <i class="el-icon-document-delete"></i>
            <p>异常订单</p>
          </div>
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'open'})">
            <i class="el-icon-set-up"></i>
            <p>紧急降锁指令</p>
          </div>
          <div class="handle-item" @click="$root.$emit('showWorkorderDialog',{type:'gate'})">
            <i class="el-icon-guide"></i>
            <p>紧急开闸指令</p>
          </div>
          <div class="handle-item" @click="$router.push('/video/list')">
            <i class="el-icon-video-camera"></i>
            <p>视频监控</p>
          </div>
        </div>
      </el-card>
      <el-card class="card">
        <div slot="header">
          <p>待办事项</p>
        </div>
        <div class="handle">
          <div class="handle-item" @click="$router.push('/workorder/revisit?status=10')">
            <i class="el-icon-connection"></i>
            <p>待跟进回访</p>
          </div>
          <div class="handle-item" @click="$router.push('/workorder/polling?status=10')">
            <i class="el-icon-view"></i>
            <p>待跟进巡检</p>
          </div>
          <div class="handle-item" @click="$router.push('/workorder/repair?status=10')">
            <i class="el-icon-setting"></i>
            <p>待跟进维修</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/consult?status=10')">
            <i class="el-icon-phone-outline"></i>
            <p>待处理咨询</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/complaint?status=0')">
            <i class="el-icon-warning-outline"></i>
            <p>待处理投诉</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/edit?status=0')">
            <i class="el-icon-news"></i>
            <p>车辆绑定申请</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/appeal?status=0')">
            <i class="el-icon-edit-outline"></i>
            <p>待处理申诉</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/repairs?status=10')">
            <i class="el-icon-cpu"></i>
            <p>待处理报修</p>
          </div>
          <div class="handle-item" @click="$router.push('/matter/collection')">
            <i class="el-icon-money"></i>
            <p>待处理催收</p>
          </div>
          <div class="handle-item" @click="$router.push('/forensics/list')">
            <i class="el-icon-copy-document"></i>
            <p>自取证审核</p>
          </div>
        </div>
      </el-card>
    </div>
    <div class="h15"></div>
    <el-card class="detail">
      <div slot="header">
        <p v-if="$store.state.caller">通话号码{{$store.state.caller}}的相关信息</p>
        <p v-else>当前暂无来电</p>
      </div>
      <el-tabs v-model="activeName" type="card" style="height:100%;">
        <el-tab-pane label="当前停车" name="now">
          <div v-if="$store.state.caller">
            <el-table :data="nowOrder" border style="width: 100%">
              <el-table-column fixed type="index" label="ID" width="60" align="center"></el-table-column>
              <el-table-column prop="plate" label="车牌号" min-width="120" align="center"></el-table-column>
              <el-table-column prop="parkingName" label="停车场" min-width="180" align="center"></el-table-column>
              <el-table-column prop="seatNum" label="车位号" min-width="120" align="center"></el-table-column>
              <el-table-column prop="payAmount" label="订单金额" min-width="90" align="center"></el-table-column>
              <el-table-column prop="enterTime" label="入场时间" min-width="140" align="center"></el-table-column>
              <el-table-column label="入场照片" width="100" align="center">
                <template slot-scope="scope">
                  <el-image style="width: 60px; height: 60px" :src="scope.row.inPic" :preview-src-list="[scope.row.inPic]">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="240" align="center">
                <template slot-scope="scope">
                  <el-button type="text" :disabled="!scope.row.lockId" @click="openLock(scope.row.lockId)">开闸放行</el-button>
                  <el-button type="text" :disabled="!scope.row.userId" @click="showCouponDialogFun(scope.row.userId,scope.row.id)">补贴优惠券</el-button>
                  <el-button type="text" @click="createErrorOrder(scope.row)">生成异常订单</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <p v-else class="no-caller">当前暂无来电</p>
        </el-tab-pane>
        <el-tab-pane label="历史停车" name="history" lazy :disabled="$store.state.caller==''">
          <matter-order :mobile="$store.state.caller"></matter-order>
        </el-tab-pane>
        <el-tab-pane label="咨询记录" name="consult" lazy :disabled="$store.state.caller==''">
          <matter-consult :mobile="$store.state.caller"></matter-consult>
        </el-tab-pane>
        <el-tab-pane label="投诉记录" name="complain" lazy :disabled="$store.state.caller==''">
          <matter-complain :mobile="$store.state.caller"></matter-complain>
        </el-tab-pane>
        <el-tab-pane label="报修记录" name="repair" lazy :disabled="$store.state.caller==''">
          <matter-repair :mobile="$store.state.caller"></matter-repair>
        </el-tab-pane>
        <el-tab-pane label="异常订单记录" name="error" lazy :disabled="$store.state.caller==''">
          <matter-error :mobile="$store.state.caller"></matter-error>
        </el-tab-pane>
        <el-tab-pane label="申诉处理" name="appeal" lazy :disabled="$store.state.caller==''">
          <matter-appeal :mobile="$store.state.caller"></matter-appeal>
        </el-tab-pane>
        <el-tab-pane label="催收处理" name="collection" lazy :disabled="$store.state.caller==''">
          <matter-collection :mobile="$store.state.caller"></matter-collection>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <el-dialog title="补贴优惠券" :visible.sync="showCouponDialog" width="500px">
      <el-form label-width="100px">
        <el-form-item :required="true" label="选择优惠券">
          <el-select v-model="coupon.couponId" placeholder="请选择优惠卷" style="width:80%">
            <el-option v-for="item in couponsList" :key="item.id" :label="`${item.title}【剩余${item.couponCount}张】`" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :required="true" label="赠送数量">
          <el-input-number v-model="coupon.couponCount" label="补偿数量">
          </el-input-number>
        </el-form-item>
        <el-form-item :required="true" label="补偿原因：">
          <el-input type="textarea" :rows="3" placeholder="请输入补偿原因" v-model="coupon.couponReason"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="compensationCoupon()">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "branch",
  title: "来电工作台",
  components: {
    "matter-order": () => import("./order/list.vue"),
    "matter-consult": () => import("./matter/consult.vue"),
    "matter-complain": () => import("./matter/complaint.vue"),
    "matter-repair": () => import("./matter/repairs.vue"),
    "matter-error": () => import("./order/error.vue"),
    "matter-appeal": () => import("./matter/appeal.vue"),
    "matter-collection": () => import("./matter/collection.vue"),
  },
  data() {
    return {
      nowOrder: [{}],
      activeName: "now",
      showCouponDialog: false,
      couponsList: [],
      coupon: {
        userId: "",
        couponId: "",
        couponCount: "",
        couponReason: "",
      },
    };
  },
  watch: {
    "$store.state.caller": function (value) {
      if (value) {
        this.loadUserNowOrder(value);
      }
    },
    activeName: function (value) {
      if (value == "now" && this.$store.state.caller) {
        this.loadUserNowOrder(this.$store.state.caller);
      }
    },
  },
  created() {
    if (this.$store.state.caller) {
      this.loadUserNowOrder(this.$store.state.caller);
    }
  },
  methods: {
    loadUserNowOrder(phone) {
      this.$http.get(this.$api.getCurrentOrderList, { phone }, (res) => {
        this.nowOrder = res.list;
      });
    },
    openLock(id) {
      let type = 2;
      this.$http.post(this.$api.openCloseLockById, { id, type }, (res) => {
        this.$message.success("开闸指令下发成功！");
      });
    },
    createErrorOrder(item) {
      this.$root.$emit("showWorkorderDialog", {
        type: "order",
        data: item,
      });
    },
    showCouponDialogFun(userId, errorOrderId) {
      Object.assign(this.$data.coupon, this.$options.data().coupon);
      this.getCouponsList();
      this.showCouponDialog = true;
      this.coupon.userId = userId;
      this.coupon.errorOrderId = errorOrderId;
    },
    getCouponsList() {
      this.$http.post(this.$api.getCouponInfo, {}, (res) => {
        this.couponsList = res.list;
      });
    },
    compensationCoupon() {
      this.$http.post(
        this.$api.compensationCoupon,
        {
          count: this.coupon.couponCount,
          errorOrderId: this.coupon.errorOrderId,
          id: this.coupon.couponId,
          reason: this.coupon.couponReason,
          userId: this.coupon.userId,
        },
        (res) => {
          this.showCouponDialog = false;
          Object.assign(this.$data.coupon, this.$options.data().coupon);
          this.$message.success("优惠券赠送成功！");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-box {
  display: flex;
  .card {
    flex: 1;
    &:first-child {
      margin-right: 7.5px;
    }
    &:last-child {
      margin-left: 7.5px;
    }
    .handle {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 100px;
      .handle-item {
        width: 100px;
        text-align: center;
        font-size: 16px;
        margin-right: 20px;
        padding: 20px 0;
        cursor: pointer;
        i {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.detail {
  height: calc(100% - 307px);
  .no-caller {
    text-align: center;
    padding: 80px 0;
    color: #cccccc;
  }
  /deep/.el-card__body {
    height: calc(100% - 90px);
    /deep/.el-tabs__content {
      height: calc(100% - 56px);
      overflow-y: auto;
    }
  }
}
</style>