<template>
  <el-dialog :title="dialogConfig.title" :close-on-click-modal="false" :visible.sync="dialogConfig.show"
    :width="dialogConfig.width" :before-close="dialogClose">
    <slot></slot>
    <span slot="footer">
      <slot name='footer'>
        <el-button @click="dialogClose">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "cui-dialog",
  props: {
    value: {
      type: Object | Array,
    },
    initial: {
      type: Object | Array,
    },
    event: {
      type: Object,
    },
  },
  model: {
    prop: "value",
    event: "close",
  },
  watch: {
    value: function (newValue) {
      this.$emit("sync", newValue);
    },
  },
  data() {
    return {
      dialogConfig: {
        type: "",
        title: "添加",
        show: false,
        width: "700px",
      },
    };
  },
  methods: {
    showDialog(type) {
      Object.assign(this.$data.dialogConfig, this.$options.data().dialogConfig);
      this.dialogConfig.type = type;
      this.dialogConfig.show = true;
      this.dialogConfig.width = this.event[type].width || "700px";
      this.dialogConfig.title = this.event[type].title;
    },
    dialogConfirm() {
      this.event[this.dialogConfig.type].confirm(this.value);
    },
    dialogClose() {
      this.dialogConfig.show = false;
      this.value instanceof Array
        ? this.$emit("sync", this.initial || [])
        : this.$emit("sync", this.initial || {});
    },
  },
};
</script>

<style>
</style>