<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="主工单ID">
          <el-input v-model="searchData.id" placeholder="请输入主工单ID查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-select v-model="searchData.parkId" filterable remote placeholder="请输入关键词搜索停车场" :remote-method="getParkAreaList">
            <el-option v-for="(item,key) in parkingAreaList" :key="key" :label="item.parkingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障设备">
          <el-select v-model="searchData.deviceType" placeholder="请选择">
            <el-option v-for="item in deviceTypeList" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="故障类型">
          <el-select v-model="searchData.dictionaryId" placeholder="请选择">
            <el-option v-for="item in brokenTypeList" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option v-for="item in filterData" :key="item.value" :label="item.value" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报修来源">
          <el-select v-model="searchData.originType" placeholder="请选择" @change="$refs.table.reloadData()">
            <el-option v-for="item in originTypeList" :key="item.value" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
          <el-button @click="Object.assign($data.searchData,$options.data().searchData)">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.viewAllParkBrokenOrderInfo" :data="searchData" ref="table" width="200" :handleData="handleData">
      <el-table-column label="序号" type="index" min-width="60" :key='0'></el-table-column>
      <el-table-column prop="receptionistId" label="客服Id" width="150" v-if="searchData.originType=='30'||searchData.originType=='50'" :key='1'></el-table-column>
      <el-table-column prop="username" label="上报人姓名" width="90" :key='2'>
        <template slot-scope="scope">{{scope.row.username?scope.row.username:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="repairName" label="维修员" width="110" :key='3'></el-table-column>
      <el-table-column label="报修来源" width="120" :key='4'>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.originType==10">APP上报</el-tag>
          <el-tag v-else-if="scope.row.originType==20" type="info">巡检员上报</el-tag>
          <el-tag v-else-if="scope.row.originType==30" type="success">来电用户上报</el-tag>
          <el-tag v-else-if="scope.row.originType==40" type="warning">自动监测上报</el-tag>
          <el-tag v-else-if="scope.row.originType==50" type="info">客服主动上报</el-tag>
          <el-tag v-else-if="scope.row.originType==60">订单异常上报</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="parkName" label="故障地点" width="160" :key='5'></el-table-column>
      <el-table-column prop="seatNum" label="故障车位" width="140" :key='6'>
        <template slot-scope="scope">{{scope.row.seatNum?scope.row.seatNum:"道闸车位"}}</template>
      </el-table-column>
      <el-table-column prop="deviceTypeName" label="故障设备" width="120" :key='7'></el-table-column>
      <el-table-column prop="brokenTypeName" label="故障类型" width="120" :key='8'></el-table-column>
      <el-table-column prop="description" label="故障描述" width="200" :key='9'></el-table-column>
      <el-table-column label="照片" width="100" :key='10'>
        <template slot-scope="scope">
          <el-image v-if="(scope.row.originType=='10'||scope.row.originType=='20')&&scope.row.photos" style="width: 50px; height: 50px" :src="scope.row.photos[0]" :preview-src-list="scope.row.photos"></el-image>
          <el-tag v-else type="info">暂无图片</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="报修时间" width="160" :key='11'></el-table-column>
      <el-table-column label="是否加急" width="80" :key='12'>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.weight=='10'" type="success">加急</el-tag>
          <el-tag v-else type="info">不加急</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180" :key='13'>
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.status==(20||30)" @click="remindRepair(scope.row.id)">提醒维修</el-button>
          <el-button type="text" v-if="scope.row.status==40" @click="Handle(scope.row.finishPhotos,scope.row.updateTime,scope.row.remark)">维修记录</el-button>
        </template>
      </el-table-column>
    </cui-table>
    <el-dialog title="维修记录详情" :visible.sync="dialogVisible" width="500px">
      <el-form ref="form" label-width="100px">
        <el-form-item label="处理时间">
          <el-input v-model="finishData.updateTime" readonly></el-input>
        </el-form-item>
        <el-form-item label="处理备注">
          <el-input v-model="finishData.remark" readonly></el-input>
        </el-form-item>
        <el-form-item label="维修照片">
          <el-image style="width: 100px; height: 100px" :src="finishData.finishPhotos[0]" :preview-src-list="finishData.finishPhotos">
          </el-image>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "workerRepair",
  title: "维修工单",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        id: "",
        mobile: "",
        parkId: "",
        deviceType: "",
        dictionaryId: "",
        filter: "",
        status: "",
        originType: "",
      },
      handleData: (list) => {
        list.forEach((e) => {
          e.photos ? (e.photos = e.photos.split(",")) : (e.photos = []);
        });
        return list;
      },
      parkingAreaList: [],
      originTypeList: [
        { value: "全部", id: "" },
        { value: "APP上报", id: "10" },
        { value: "巡检员上报", id: "20" },
        { value: "用户来电上报", id: "30" },
        { value: "客服主动上报", id: "50" },
        { value: "订单异常上报", id: "60" },
        { value: "自动监测上报", id: "40" },
      ],
      filterData: [
        { value: "全部", id: "" },
        { value: "待处理", id: "10" },
        { value: "巡检中", id: "20" },
        { value: "巡检无故障", id: "30" },
        { value: "巡检已处理", id: "40" },
        { value: "巡检已确认", id: "110" },
        { value: "维修处理中", id: "50" },
        { value: "维修延期处理", id: "60" },
        { value: "维修已完成", id: "70" },
        { value: "需回访", id: "80" },
        { value: "回访完成", id: "90" },
        { value: "全部完成", id: "100" },
      ],
      deviceTypeList: [],
      brokenTypeList: [],
      dialogVisible: false,
      finishData: {
        updateTime: "",
        remark: "",
        finishPhotos: [],
      },
    };
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.status = this.$route.query.status;
    }
    this.searchData.id = this.$route.query.id || this.$route.query.boid || "";
    this.selectItemList();
  },
  methods: {
    createRevisitOrder(item) {
      item.orderType = "10";
      this.$root.$emit("showWorkorderDialog", {
        type: "revisit",
        data: item,
        callback: () => {
          this.$refs.table.reloadData();
        },
      });
    },
    remindRepair(id) {
      this.$http.get(this.$api.remindRepair, { brokenOrderId: id }, (res) => {
        this.$message.success("维修提醒成功！");
      });
    },
    Handle(finishPhotos, updateTime, remark) {
      this.finishData.updateTime = updateTime;
      this.finishData.remark = remark;
      this.finishData.finishPhotos = finishPhotos.split(",");
      this.dialogVisible = true;
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
    selectItemList() {
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "device_type",
        },
        (res) => {
          this.deviceTypeList = res.list;
        }
      );
      this.$http.post(
        this.$api.selectItemList,
        {
          type: "broken",
        },
        (res) => {
          this.brokenTypeList = res.list;
        }
      );
    },
  },
};
</script>

<style>
</style>