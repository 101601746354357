<template>
  <div>暂无消息记录</div>
</template>

<script>
export default {
  name: "message",
  title: "我的消息",
};
</script>

<style>
</style>