<template>
  <div style="background-color:#fff">
    <el-form label-width="110px">
      <el-form-item label="回访号码">
        <el-input v-model="revisitInfo.mobile"></el-input>
      </el-form-item>
      <el-form-item label="回访单类型">
        {{orderType[revisitInfo.orderType]}}
      </el-form-item>
      <el-form-item label="主工单ID">
        <el-input v-model="revisitInfo.targetId" readonly></el-input>
      </el-form-item>
      <el-form-item label="回访备注">
        <el-input v-model="revisitInfo.remark" type="textarea" :rows="5" placeholder="请输入回访备注"></el-input>
      </el-form-item>
      <el-form-item label="回访时间">
        <el-date-picker v-model="revisitInfo.taskTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createRevisitOrder()">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createWorkorderRevisit",
  title: "新建回访工单",
  props: ["data", "callback"],
  data() {
    return {
      revisitInfo: {
        mobile: "",
        orderType: "",
        remark: "",
        targetId: "",
        taskTime: "",
      },
      orderType: {
        10: "维修回访单",
        20: "投诉回访单",
        30: "咨询回访单",
        40: "订单异常回访单",
      },
    };
  },
  watch: {
    data: function (value) {
      this.revisitInfo.mobile = value.mobile;
      this.revisitInfo.orderType = value.orderType;
      this.revisitInfo.targetId = value.id;
    },
  },
  mounted() {
    this.revisitInfo.mobile = this.$store.state.caller || "";
    this.revisitInfo.mobile = this.data.mobile;
    this.revisitInfo.orderType = this.data.orderType;
    this.revisitInfo.targetId = this.data.id;
  },
  methods: {
    createRevisitOrder() {
      this.$http.post(
        this.$api.insertReturnVisitOrder,
        this.revisitInfo,
        (res) => {
          this.$root.$emit("closeWorkorderDialog");
          this.$message.success("回访工单添加成功，注意按时跟进！");
          Object.assign(
            this.$data.revisitInfo,
            this.$options.data().revisitInfo
          );
          this.callback();
        }
      );
    },
  },
};
</script>

<style>
</style>