<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="订单号">
          <el-input v-model="searchData.orderId" placeholder="请输入订单号查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="searchData.plate" placeholder="请输入车牌号查找"></el-input>
        </el-form-item>
        <el-form-item label="车位号">
          <el-input v-model="searchData.seatNum" placeholder="请输入车位号"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="searchData.status">
            <el-option label="审核中" value="1"></el-option>
            <el-option label="审核通过" value="2"></el-option>
            <el-option label="审核拒绝" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selfForensicsList" :data="searchData" ref="table" width="200">
      <el-table-column fixed prop="id" label="ID" width="150"></el-table-column>
      <el-table-column label="姓名" min-width="150">
        <template slot-scope="scope">
          <p>{{scope.row.parkUserDTO.userName}}</p>
        </template>
      </el-table-column>
      <el-table-column label="手机号" min-width="150">
        <template slot-scope="scope">
          <p>{{scope.row.parkUserDTO.mobile}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="plate" label="车牌号" min-width="100"></el-table-column>
      <el-table-column prop="spaceNum" label="车位号" min-width="100"></el-table-column>
      <el-table-column prop="bodyImage" label="车身图" width="100">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.bodyImage" :preview-src-list="[scope.row.bodyImage,scope.row.plateImage,scope.row.spaceImage]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="plateImage" label="车牌图" width="100">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.plateImage" :preview-src-list="[scope.row.bodyImage,scope.row.plateImage,scope.row.spaceImage]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="spaceImage" label="车位图" width="100">
        <template slot-scope="scope">
          <el-image style="width: 70px; height: 70px" :src="scope.row.spaceImage" :preview-src-list="[scope.row.bodyImage,scope.row.plateImage,scope.row.spaceImage]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间" width="150"></el-table-column>
      <el-table-column prop="status" label="审核状态" width="100">
        <template slot-scope="scope">
          <p v-if="scope.row.status=='1'">待审核</p>
          <p v-if="scope.row.status=='2'">审核通过</p>
          <p v-if="scope.row.status=='3'">审核驳回</p>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-popover style="margin-right:10px;" v-if="scope.row.status=='1'" placement="right" width="100" trigger="click">
            <el-button type="text" @click="beforeAuditInfo(scope.row.id,'1','2')">蓝牌</el-button>
            <el-button type="text" @click="beforeAuditInfo(scope.row.id,'5','2')">绿牌</el-button>
            <el-button type="text" @click="beforeAuditInfo(scope.row.id,'2','2')">黄牌</el-button>
            <el-button type="text" @click="beforeAuditInfo(scope.row.id,'3','2')">白牌</el-button>
            <el-button type="text" @click="beforeAuditInfo(scope.row.id,'4','2')">黑牌</el-button>
            <el-button slot="reference" type="text">通过</el-button>
          </el-popover>
          <el-button v-if="scope.row.status=='1'" type="text" @click="beforeAuditInfo(scope.row.id,scope.row.plateColour,'3')">驳回</el-button>
          <el-button v-if="scope.row.status=='3'" type="text" @click="showReasion(scope.row.rejectionReason)">查看原因</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "forensicsList",
  title: "自取证审核",
  data() {
    return {
      searchData: {
        orderId: "",
        mobile: "",
        plate: "",
        seatNum: "",
        status: "1",
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
    };
  },
  methods: {
    beforeAuditInfo(id, plateColour, status) {
      if (status == "3") {
        this.$prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            this.auditInfo(id, plateColour, status, value);
          })
          .catch(() => {});
      } else {
        this.auditInfo(id, plateColour, status, "");
      }
    },
    auditInfo(id, plateColour, status, reason) {
      this.$http.post(
        this.$api.checkSelfForensics,
        { id, plateColour, status, reason },
        (res) => {
          this.$message.success("审核操作成功！");
          this.$refs.table.reloadData();
        }
      );
    },
    showReasion(reason) {
      this.$alert(reason, "驳回原因", {
        confirmButtonText: "关闭",
      });
    },
  },
};
</script>