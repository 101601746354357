export default {
    data() {
        return {
            globalProps: {
                lazy: true,
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    this.globalLoadAddressLazy(node.value || '99999').then((res) => {
                        const nodes = res.map((e) => ({
                            value: e.id,
                            label: e.name,
                            leaf: level >= 2,
                        }));
                        resolve(nodes);
                    });
                },
            },
        }
    },
    methods: {
        goBack: function () {
            this.$router.back()
        },
        //全局上传图片
        globalUploadFunction(file) {
            let promiseAll = []
            file.forEach(e => {
                promiseAll.push(new Promise(resolve => {
                    this.$http.upload(this.$api.uploadFile, e, (res) => {
                        resolve(res)
                    })
                }))
            })
            return Promise.all(promiseAll)
        },
        // 配合Elementui级联选择器树形懒加载地址库
        globalLoadAddressLazy(fid) {
            return new Promise(resolve => {
                this.$http.get(this.$api.getChildCity, { id: fid || '99999' }, (res) => {
                    resolve(res.list)
                });
            })
        },
        // 获取Vuex.state.systemRole
        globalLoadSystemRole() {
            return new Promise(resolve => {
                this.$http.post(this.$api.loadRoleList, {
                    currentPage: 1,
                    pageSize: 99999,
                }, res => {
                    this.$store.commit('systemRole/setSystemRole', res.list)
                    resolve(res.list)
                })
            })
        },
        // 获取Vuex.state.deviceSupplier
        globalLoadDeviceSupplier() {
            return new Promise(resolve => {
                this.$http.post(this.$api.selectDeviceFactoryList, {
                    currentPage: 1,
                    pageSize: 99999,
                }, res => {
                    this.$store.commit('deviceSupplier/setDeviceSupplier', res.list)
                    resolve(res.list)
                })
            })
        },
        // 获取Vuex.state.parkList
        globalLoadParkList() {
            return new Promise(resolve => {
                this.$http.post(this.$api.getParkAreaList, {
                    currentPage: 1,
                    pageSize: 99999,
                }, res => {
                    this.$store.commit('park/setParkList', res.list)
                    resolve(res.list)
                })
            })
        }
    }
}