<template>
  <div>
    <slot :init="initMap">
      <el-button @click="initMap">选取坐标</el-button>
    </slot>
    <el-dialog :title="selectType|mapSelectTypeStatus" :visible.sync="dialogVisible" width="850px" append-to-body
      :before-close="beforeClose">
      <el-form inline>
        <el-form-item label="请输入地点">
          <el-input v-model="searchKeyword" clearable placeholder="例如：北京天安门广场"></el-input>
        </el-form-item>
        <el-form-item label="进行位置">
          <el-button type="primary" @click="searchLocation">查询</el-button>
          <el-button type="primary" @click="clearMap">清除画布</el-button>
        </el-form-item>
      </el-form>
      <div ref="Map" class="map-container"></div>
      <span slot="footer">
        <el-button @click="beforeClose">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import locationImg from "@/assets/images/location.png";
export default {
  name: "cui-location",
  props: {
    value: {
      type: null | Object | Array,
    },
    selectType: {
      type: String,
      default: "marker",
    },
  },
  data() {
    return {
      dialogVisible: false,
      Map: null,
      searchKeyword: "",
      mapGeocoder: null,
      mapMouseTool: null,
      mapMarker: null,
      mapMarkerIcon: null,
      dotAarry: []
    };
  },
  created() {
    this.dotAarry = this.value || [];
  },
  methods: {
    // 初始化地图
    initMap() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.Map = new AMap.Map(this.$refs.Map);
        (this.mapMarkerIcon = new AMap.Icon({
          size: new AMap.Size(50, 50),
          image: locationImg,
          imageSize: new AMap.Size(50, 50),
          anchor: "center",
        })),
          // 加载高德地理编码服务
          this.Map.plugin("AMap.Geocoder", () => {
            this.mapGeocoder = new AMap.Geocoder({ city: "全国" });
          });
        // 加载高德鼠标工具
        this.Map.plugin("AMap.MouseTool", () => {
          this.mapMouseTool = new AMap.MouseTool(this.Map);
          switch (this.selectType) {
            case "marker": {
              this.mapMouseTool.marker({});
              break;
            }
            case "polyline": {
              this.mapMouseTool.polyline({ strokeColor: "#80d8ff" });
              break;
            }
            case "polygon": {
              this.mapMouseTool.polygon({
                fillColor: "#00b0ff",
                strokeColor: "#80d8ff",
              });
              break;
            }
            case "rectangle": {
              this.mapMouseTool.rectangle({
                fillColor: "#00b0ff",
                strokeColor: "#80d8ff",
              });
              break;
            }
            case "circle": {
              this.mapMouseTool.circle({
                fillColor: "#00b0ff",
                strokeColor: "#80d8ff",
              });
              break;
            }
          }
          this.mapMouseTool.on("draw", (e) => {
            this.dotAarry.push(e.obj);
            // this.dotAarry.push({
            //   lng: e.obj.Ce.position.lng,
            //   lat: e.obj.Ce.position.lat,
            // });
          });
        });
      });
    },
    clearMap() {
      this.Map.remove(this.dotAarry);
      this.dotAarry = [];
    },
    searchLocation() {
      if (this.searchKeyword) {
        this.mapGeocoder.getLocation(this.searchKeyword, (status, result) => {
          if (status === "complete" && result.info === "OK") {
            this.mapMarker && this.Map.remove(this.mapMarker);
            let lng = result.geocodes[0].location.lng,
              lat = result.geocodes[0].location.lat,
              title = result.geocodes[0].formattedAddress;
            this.Map.setZoomAndCenter(15, [lng, lat]);
            this.Map.add(
              (this.mapMarker = new AMap.Marker({
                position: new AMap.LngLat(lng, lat),
                offset: new AMap.Pixel(-10, -10),
                icon: this.mapMarkerIcon,
                title: title,
              }))
            );
          } else {
            this.$message.info("未查询到相关地点！");
          }
        });
      } else {
        this.$message.info("请输入关键词或经纬度查询！");
      }
    },
    beforeClose() {
      this.Map.destroy();
      this.searchKeyword = this.lngLat = "";
      this.dotAarry = [];
      this.dialogVisible = false;
    },
    dialogConfirm() {
      this.$emit("input", this.dotAarry);
      this.beforeClose();
    },
  },
};
</script>

<style lang="css" scoped>
.map-container {
  width: 100%;
  height: 500px;
}
</style>