<template>
  <div>
    <slot :init="initMap">
      <el-button @click="initMap">查看</el-button>
    </slot>
    <el-dialog title="信息查看" :visible.sync="dialogVisible" width="850px" append-to-body :before-close="beforeClose">
      <div ref="Map" class="map-container"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cui-map",
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "marker", //marker点，line线条，polygon多边形
    },
  },
  data() {
    return {
      dialogVisible: false,
      Map: null,
      path: [],
    };
  },
  methods: {
    initMap() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.Map = new AMap.Map(this.$refs.Map);
        let dots = this.value.split(",");
        let center = this.getCenter(dots);
        this.Map.setZoomAndCenter(17, center);
        if (this.type == "marker") {
          dots.forEach((e) => {
            let item = e.split("-");
            this.path.push(
              new AMap.Marker({
                position: new AMap.LngLat(item[0], item[1]),
              })
            );
          });
        } else {
          dots.forEach((e) => {
            let item = e.split("-");
            this.path.push(new AMap.LngLat(item[0], item[1]));
          });
        }
        switch (this.type) {
          case "marker":
            this.Map.add(this.path);
            break;
          case "line":
            this.Map.add(
              new AMap.Polyline({
                path: this.path,
                borderWeight: 2, // 线条宽度，默认为 1
                strokeColor: "#357cff", // 线条颜色
                fillOpacity: 0.5,
                lineJoin: "round", // 折线拐点连接处样式
              })
            );
            break;
          case "polygon":
            this.Map.add(
              new AMap.Polygon({
                path: this.path,
                fillColor: "#357cff", // 多边形填充颜色
                borderWeight: 2, // 线条宽度，默认为 1
                fillOpacity: 0.5,
                strokeColor: "red", // 线条颜色
              })
            );
            break;
        }
      });
    },
    // [lng-lat,lng-lat,lng-lat]
    getCenter(lngLat) {
      let lngCount = 0,
        latCount = 0;
      lngLat.forEach((e) => {
        let item = e.split("-");
        lngCount += parseFloat(item[0]);
        latCount += parseFloat(item[1]);
      });
      return [
        (lngCount / lngLat.length).toFixed(6),
        (latCount / lngLat.length).toFixed(6),
      ];
    },
    beforeClose() {
      this.Map.destroy();
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 500px;
}
</style>