<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="来电号码">
        <el-input v-model="repairData.mobile" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="指派人员">
        <el-select v-model="repairData.repairman">
          <el-option v-for="(item,key) in parkWorkerList" :key="key" :label="`${item.nickName}[${item.mobile}]-${item.isOnLine=='1'?'在线':'离线'}`" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-select v-model="repairData.deviceType">
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障类型">
        <el-select v-model="repairData.brokenType">
          <el-option v-for="item in brokenTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障描述">
        <el-input type="textarea" v-model="repairData.description" :rows="5" placeholder="请输入工单描述">
        </el-input>
      </el-form-item>
      <el-form-item label="加急处理">
        <el-radio-group v-model="repairData.weight" size="small">
          <el-radio :label="0" border>不加急</el-radio>
          <el-radio :label="10" border disabled>加急处理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="createRepair">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createWorkorderRepair",
  title: "新建维修工单",
  props: ["data", "callback"],
  data() {
    return {
      repairData: {
        mobile: "",
        parkId: "",
        seatNum: "",
        repairman: "",
        weight: 0,
        deviceType: "",
        originType: "",
        clientType: 60,
        logsType: 0,
        logsId: "",
        brokenType: "",
        description: "",
      },
      parkWorkerList: [],
      deviceTypeList: [],
      brokenTypeList: [],
    };
  },
  watch: {
    data: function (value) {
      Object.assign(this.$data.repairData, this.$options.data().repairData);
      this.repairData.mobile = value.mobile;
      this.repairData.parkId = value.parkId;
      this.repairData.seatNum = value.seatNum;
      this.repairData.originType = value.originType;
      this.repairData.deviceType = value.deviceType;
      this.repairData.brokenType = value.brokenType;
      this.repairData.description = value.description;
      this.repairData.logsId = value.logsId;
      this.getParkWorkerList(value.parkId);
    },
  },
  created() {
    this.repairData.mobile = this.$store.state.caller || "";
    this.getParkWorkerList(this.data.parkId);
    this.$http.all(
      [
        {
          type: "post",
          url: this.$api.selectItemList,
          data: { type: "device_type" },
        },
        {
          type: "post",
          url: this.$api.selectItemList,
          data: { type: "broken" },
        },
      ],
      (res) => {
        this.deviceTypeList = res[0].list;
        this.brokenTypeList = res[1].list;
        this.repairData.mobile = this.data.mobile;
        this.repairData.parkId = this.data.parkId;
        this.repairData.seatNum = this.data.seatNum;
        this.repairData.originType = this.data.originType;
        this.repairData.deviceType = this.data.deviceType;
        this.repairData.brokenType = this.data.brokenType;
        this.repairData.description = this.data.description;
        this.repairData.logsId = this.data.logsId;
      }
    );
  },
  methods: {
    createRepair() {
      this.$http.post(this.$api.appointBrokenOrder, this.repairData, (res) => {
        this.$root.$emit("closeWorkorderDialog");
        this.$message.success("维修工单创建成功！");
        Object.assign(this.$data.repairData, this.$options.data().repairData);
        this.callback();
      });
    },
    getParkWorkerList(parkId) {
      this.$http.post(
        this.$api.selectEmpList,
        {
          currentPage: 1,
          pageSize: 99999,
          parkId: parkId,
          roleType: "5",
        },
        (res) => {
          this.parkWorkerList = res.list;
        }
      );
    },
  },
};
</script>
