<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="客服Id">
          <el-input v-model="searchData.receptionistId" placeholder="请输入客服Id查找"></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户名">
          <el-input v-model="searchData.userName" placeholder="请输入用户名查找"></el-input>
        </el-form-item> -->
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="回访类型">
          <el-select v-model="searchData.orderType" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="设备维修" value="10"></el-option>
            <el-option label="用户投诉" value="20"></el-option>
            <el-option label="用户咨询" value="30"></el-option>
            <el-option label="订单异常" value="40"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回访状态">
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option value label="全部"></el-option>
            <el-option value="10" label="待回访"></el-option>
            <el-option value="20" label="已解决"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.selectReturnVisitOrderList" :data="searchData" ref="table" width="200">
      <el-table-column fixed label="序号" type="index" min-width="60"></el-table-column>
      <el-table-column prop="receptionistId" label="客服id" min-width="160"></el-table-column>
      <el-table-column prop="userName" label="用户姓名" min-width="100">
        <template slot-scope="scope">{{scope.row.userName?scope.row.userName:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" min-width="120"></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="250"></el-table-column>
      <el-table-column prop="createTime" label="生成回访时间" min-width="150"></el-table-column>
      <el-table-column prop="taskTime" label="回访提醒时间" min-width="150"></el-table-column>
      <el-table-column label="回访类型" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.orderType==10" type="warning">设备维修</el-tag>
          <el-tag v-if="scope.row.orderType==20" type="info">用户投诉</el-tag>
          <el-tag v-if="scope.row.orderType==30" type="success">用户咨询</el-tag>
          <el-tag v-if="scope.row.orderType==40" type="danger">订单异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="回访状态" width="100">
        <template slot-scope="scope">
          <el-tag effect="dark" v-if="scope.row.status==20" type="success">已解决</el-tag>
          <el-tag effect="dark" v-else :type="scope.row.type">待回访</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="170">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.status!=10" type="text" @click="outbound(scope.row)">外呼</el-button>
          <el-button :disabled="scope.row.status!=10" type="text" @click="addRevisitedInfo(scope.row)">添加跟进</el-button>
          <el-button type="text" @click="selectHandleRecord(scope.row.id)">跟进记录</el-button>
        </template>
      </el-table-column>
    </cui-table>

    <el-dialog :title="dialogData.title" :visible.sync="dialogData.show" :width="dialogData.width" :modal="false" :close-on-click-modal="false" :modal-append-to-body="false">
      <el-form label-width="100px" v-if="dialogData.type=='deal'">
        <el-form-item label="回访内容">
          <el-input v-model="dealInfo.content" type="textarea" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="回访备注" v-if="dealInfo.status==10">
          <el-input v-model="dealInfo.remark" type="textarea" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="回访时间" v-if="dealInfo.status==10">
          <el-date-picker v-model="dealInfo.taskTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否解决">
          <el-radio-group v-model="dealInfo.status">
            <el-radio :label="10" border>回访需再次跟进</el-radio>
            <el-radio :label="20" border>已解决并关闭主工单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dealReturnVisitOrderInfo()">提 交</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="orderInfo" height="350" border style="width: 100%" v-else>
        <el-table-column prop="handlerName" label="跟进客服" width="100" align="center"></el-table-column>
        <el-table-column prop="createTime" label="跟进时间" width="150" align="center"></el-table-column>
        <el-table-column prop="replyContent" label="跟进记录" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "workerRevisit",
  title: "回访工单",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        receptionistId: "",
        // userName: "",
        mobile: "",
        orderType: "",
        status: "",
      },
      dialogData: {
        title: "",
        show: false,
        width: "500px",
        type: "deal",
      },
      dealInfo: {
        content: "",
        remark: "",
        status: 10,
        taskTime: "",
      },
      orderInfo: [],
    };
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.status = this.$route.query.status;
    }
  },
  methods: {
    addRevisitedInfo(item) {
      this.dialogData.title = "添加回访记录";
      this.dialogData.show = true;
      this.dialogData.width = "500px";
      this.dialogData.type = "deal";
      this.dealInfo.id = item.id;
      this.dealInfo.mobile = item.mobile;
      this.dealInfo.orderType = item.orderType;
      this.dealInfo.remark = item.remark;
      this.dealInfo.targetId = item.targetId;
    },
    outbound(item) {
      this.addRevisitedInfo(item);
      this.$root.$emit("outbound", item.mobile);
    },
    dealReturnVisitOrderInfo() {
      this.$http.post(
        this.$api.dealReturnVisitOrderInfo,
        this.dealInfo,
        (res) => {
          Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
          Object.assign(this.$data.dialogData, this.$options.data().dialogData);
          this.$message.success("跟进记录添加成功！");
          this.$refs.table.reloadData();
        }
      );
    },
    selectHandleRecord(id) {
      this.$http.post(
        this.$api.selectHandleRecord,
        {
          currentPage: 1,
          pageSize: 99999,
          returnVisitOrderId: id,
        },
        (res) => {
          this.orderInfo = res.list;
          this.dialogData.title = "回访历史记录";
          this.dialogData.show = true;
          this.dialogData.width = "700px";
          this.dialogData.type = "history";
        }
      );
    },
  },
};
</script>

<style>
</style>