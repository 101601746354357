<template>
  <div class="header">
    <div class="item left">
      <el-image :src="logoImg" class="logo-img">
        <div slot="error" class="image-slot"></div>
      </el-image>
      <p>{{systemName}}</p>
    </div>
    <div class="item right">
      <div class="right-item">
        <p><i class="iconfont icon-yonghu-tianchong"></i>个人中心</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "componentsHeader",
  props: ["value"],
  data() {
    return {
      logoImg: "",
      systemName: "智慧停车云平台",
    };
  },
  created() {
    this.getUsetInfo();
  },
  methods: {
    getUsetInfo() {
      this.$http.post(this.$api.getOneServicer, null, (res) => {
        this.$store.commit("userInfo/setUserInfo", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: $maincolor;
  color: #fff;
  padding: 0 20px;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .iconfont {
      font-size: 30px;
      cursor: pointer;
    }
    .logo-img {
      width: auto;
      height: 50px;
      margin-left: 20px;
    }
    p {
      font-size: 24px;
      margin: 0;
    }
  }
  .right {
    font-size: 14px;
    .right-item {
      cursor: pointer;
      height: 50px;
      padding: 0 20px;
      background-color: transparent;
      transition-duration: 0.5s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.315);
      }
      p {
        margin: 0;
        line-height: 50px;
      }
      .iconfont {
        margin-right: 6px;
      }
    }
  }
}
</style>