<template>
  <div class="cui-table">
    <el-table class="table" :data="dataList" border stripe :header-cell-style="tabelHeaderStyle" :cell-style="tabelCellStyle" :tree-props="treeProps" @selection-change="handleSelectionChange"
      row-key="id">
      <slot></slot>
    </el-table>
    <el-pagination v-if="showPage" class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="PagsNum" :page-sizes="PageSelect" :page-size="PageSize"
      :total="totleRows" layout="total,sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "cui-table",
  props: {
    //请求地址，post
    url: { type: String, required: true },
    //请求参数
    data: {
      type: Object,
      default: function () {
        return { currentPage: 1, pageSize: 10, count: 0 };
      },
    },
    //树形表格
    treeProps: {
      type: Object,
    },
    // 选择框表格
    selectionChange: {
      type: Function,
    },
    // 返回数据的前置处理
    handleData: {
      type: Function,
    },
    // 是否显示分页
    showPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      PageSelect: [1, 10, 20, 50, 100],
      PageSize: 10,
      dataList: [],
      totleRows: 0,
      PagsNum: 1,
      tabelHeaderStyle: {
        "background-color": "#f5f5f5",
        "text-align": "center",
        "font-weight": "blod",
        color: "#666",
      },
      tabelCellStyle: { "text-align": "center" },
    };
  },
  created() {
    this.loadList(this.data);
  },
  methods: {
    loadList(data) {
      this.$http.post(this.url, data, (res) => {
        this.PagsNum = res.currentPage;
        this.PageSize = res.pageSize;
        this.totleRows = res.count;
        this.handleData
          ? (this.dataList = this.handleData(res.list))
          : (this.dataList = res.list);
      });
    },
    reloadData(thispage) {
      this.loadList(
        Object.assign({}, this.data, {
          currentPage: thispage ? this.PagsNum : 1,
          pageSize: this.PageSize,
        })
      );
    },
    handleSizeChange(value) {
      this.loadList(
        Object.assign({}, this.data, {
          currentPage: this.PagsNum,
          pageSize: value,
        })
      );
    },
    handleCurrentChange(value) {
      this.loadList(
        Object.assign({}, this.data, {
          currentPage: value,
          pageSize: this.PageSize,
        })
      );
    },
    handleSelectionChange(val) {
      this.selectionChange(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.cui-table {
  width: 100%;
  display: block;
  margin-top: 15px;
  .pagination {
    text-align: center;
    padding: 15px 5px 5px;
  }
}
</style>