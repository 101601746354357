<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="停车场">
          <el-input v-model="searchData.parkName" placeholder="请输入停车场名称查找"></el-input>
        </el-form-item>
        <el-form-item label="时间（默认一周内）">
          <el-date-picker v-model="searchData.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择开始时间"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectNoOrderSeatNum" :data="searchData" ref="table">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="parkingName" label="停车场"></el-table-column>
      <el-table-column prop="seatNum" label="车位号"></el-table-column>
    </cui-table>

  </div>
</template>

<script>
export default {
  name: "orderErrorSeat",
  title: "异常车位",
  data() {
    return {
      searchData: {
        parkName: "",
        startTime: new Date(new Date().getTime()-604800000),
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
    };
  },
};
</script>
