export default {
    state: {
        accessToken: '',
        aliPassword: '',
        aliUser: '',
        callCount: '',
        city: '',
        customerServiceId: '',
        customerServiceType: '',
        duration: '',
        extensionNumber: '',
        id: '',
        isDelete: '',
        nickName: '',
        password: '',
        permissions: '',
        refreshToken: '',
        role: '',
        status: '',
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.accessToken = userInfo.accessToken
            state.aliPassword = userInfo.aliPassword
            state.aliUser = userInfo.aliUser
            state.callCount = userInfo.callCount
            state.city = userInfo.city
            state.customerServiceId = userInfo.customerServiceId
            state.customerServiceType = userInfo.customerServiceType
            state.duration = userInfo.duration
            state.extensionNumber = userInfo.extensionNumber
            state.id = userInfo.id
            state.isDelete = userInfo.isDelete
            state.nickName = userInfo.nickName
            state.password = userInfo.password
            state.permissions = userInfo.permissions
            state.refreshToken = userInfo.refreshToken
            state.role = userInfo.role
            state.status = userInfo.status
        }
    },
    namespaced: true
}