<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="用户名">
          <el-input v-model="searchData.userName" placeholder="请输入用户名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectParkArrearsCarInfo" :data="searchData" ref="table" width="200">
      <el-table-column fixed prop="userId" label="用户ID" width="150"></el-table-column>
      <el-table-column prop="userName" label="用户名">
        <template slot-scope="scope">{{scope.row.userName?scope.row.userName:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="userMobile" label="联系方式"></el-table-column>
      <el-table-column prop="arrearsAmount" label="欠款金额"></el-table-column>
      <el-table-column prop="arrearsCount" label="欠款次数"></el-table-column>
      <el-table-column prop="urgeCount" label="催收次数"></el-table-column>
      <el-table-column label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <el-button type="text" @click="$root.$emit('outbound', scope.row.userMobile)">外呼</el-button>
          <el-button type="text" @click="addUrge(scope.row.userId)">添加记录</el-button>
          <el-button type="text" @click="selectArrearsDetail(scope.row.userId)">欠款详情</el-button>
          <el-button type="text" @click="selectUrgeDetail(scope.row.userId)">催收记录</el-button>
        </template>
      </el-table-column>
    </cui-table>

    <cui-dialog ref="dialog" :event="dialogEvent" v-model="dialogData" @sync="(value)=>{dialogData = value;}">
      <cui-table v-if="dialogType=='selectArrearsDetail'" :url='$api.selectArrearsDetail' :data='dialogData' ref="selectArrearsDetail">
        <el-table-column prop="id" label="订单号" width="160"></el-table-column>
        <el-table-column prop="plate" label="车牌号" width="100"></el-table-column>
        <el-table-column prop="enterTime" label="入场时间"></el-table-column>
        <el-table-column prop="leaveTime" label="出场时间"></el-table-column>
        <el-table-column prop="arrearsAmount" label="欠款金额"></el-table-column>
      </cui-table>
      <cui-table v-if="dialogType=='selectUrgeDetail'" :url='$api.selectUrgeDetail' :data='dialogData' ref="selectUrgeDetail">
        <el-table-column prop="createTime" label="催收时间" width="140"></el-table-column>
        <el-table-column prop="content" label="催收记录" min-width="100"></el-table-column>
        <el-table-column prop="nickName" label="催收人员" min-width="100"></el-table-column>
        <el-table-column prop="customerServiceId" label="催收人员ID" min-width="100">
        </el-table-column>
      </cui-table>
      <template #footer><span>&nbsp;</span></template>
    </cui-dialog>
  </div>
</template>

<script>
export default {
  name: "matterCollection",
  title: "欠款催收",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        userName: "",
        mobile: "",
      },
      dialogData: {},
      dialogType: "",
      dialogEvent: {
        UrgeDetail: {
          title: "催收历史记录",
          width: "900px",
        },
        ArrearsDetail: {
          title: "欠款详情",
          width: "900px",
        },
      },
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
  },
  methods: {
    addUrge(userId) {
      this.$prompt("请输入催收记录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http.post(
            this.$api.insertUrgeRecord,
            { content: value, userId: userId },
            (res) => {
              this.$message.success("催收记录添加成功！");
              this.$refs.table.reloadData();
            }
          );
        })
        .catch(() => {});
      //this.$root.$emit("showWorkorderDialog", { type: "revisit", data: null });
    },
    selectUrgeDetail(userId) {
      this.dialogType = "selectUrgeDetail";
      this.dialogData = {
        currentPage: 1,
        pageSize: 10,
        userId: userId,
      };
      this.$refs.dialog.showDialog("UrgeDetail");
      this.$nextTick(() => {
        this.$refs.selectUrgeDetail.reloadData();
      });
    },
    selectArrearsDetail(userId) {
      this.dialogType = "selectArrearsDetail";
      this.dialogData = {
        currentPage: 1,
        pageSize: 10,
        userId: userId,
      };
      this.$refs.dialog.showDialog("ArrearsDetail");
      this.$nextTick(() => {
        this.$refs.selectArrearsDetail.reloadData();
      });
    },
  },
};
</script>

<style>
</style>