<template>
  <div>
    <el-card>
      <el-form inline ref="form">
        <el-form-item label="车位号">
          <el-input v-model="searchData.seatNum" placeholder="请输入车位号"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="searchData.datetimeRange" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.status">
            <el-option label="全部" value=""></el-option>
            <el-option label="待审核" value="0"></el-option>
            <el-option label="已通过" value="1"></el-option>
            <el-option label="已拒绝" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectPlateReportList" :data="searchData" ref="table" width="200">
      <el-table-column fixed prop="orderId" label="订单ID" width="150"></el-table-column>
      <el-table-column label="车位号" prop="seatNum" min-width="100"></el-table-column>
      <el-table-column label="上报人" prop="reportUserName" min-width="100"></el-table-column>
      <el-table-column label="上报原因" prop="reportType" min-width="100"></el-table-column>
      <el-table-column label="上报图片" prop="seatNum" min-width="200">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.seatNumImage" :preview-src-list="[scope.row.seatNumImage,scope.row.spaceImage]"></el-image>
          &nbsp;
          <el-image style="width: 50px; height: 50px" :src="scope.row.spaceImage" :preview-src-list="[scope.row.seatNumImage,scope.row.spaceImage]"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="上报时间" prop="reportTime" min-width="150"></el-table-column>
      <el-table-column label="状态" prop="status" min-width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status=='0'">等待审核</el-tag>
          <el-tag v-if="scope.row.status=='1'" type="success">审核通过</el-tag>
          <el-tag v-if="scope.row.status=='2'" type="info">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="handlerUserName" min-width="100"></el-table-column>
      <el-table-column label="操作时间" prop="handlerTime" min-width="150"></el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template slot-scope="scope">
          <el-button type="text" :disabled="scope.row.status!=0" @click="updatePlateReport(scope.row.id,'1')">审核通过</el-button>
          <el-button type="text" :disabled="scope.row.status!=0" @click="updatePlateReport(scope.row.id,'0')">审核拒绝</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "orderNoplate",
  title: "无牌订单审核",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        status: "0",
        seatNum: "",
        datetimeRange: [],
        startTime: "",
        endTime: "",
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
    };
  },
  watch: {
    "searchData.datetimeRange": function (value) {
      if (value && value.length == 2) {
        this.searchData.startTime = value[0];
        this.searchData.endTime = value[1];
      } else {
        this.searchData.startTime = "";
        this.searchData.endTime = "";
      }
    },
  },
  methods: {
    updatePlateReport(id, status) {
      this.$http.post(this.$api.updatePlateReport, { id, status }, (res) => {
        this.$message.success("审核成功");
        this.$refs.table.reloadData();
      });
    },
  },
};
</script>
