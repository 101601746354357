<template>
  <div style="background-color:#fff">
    <el-form ref="form" label-width="100px">
      <el-form-item label="通知停车场">
        <el-select v-model="notifyData.parkId" filterable remote placeholder="请输入关键词搜索停车场" :remote-method="getParkAreaList">
          <el-option v-for="(item,key) in parkingAreaList" :key="key" :label="item.parkingName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="范围类型">
        <el-radio-group v-model="notifyData.rangeType">
          <el-radio label="1" border>按角色推送</el-radio>
          <el-radio label="2" border>个人通知</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="人员类型" v-if="notifyData.rangeType=='1'">
        <el-checkbox-group v-model="notifyData.roleTypeList">
          <el-checkbox label="1">管理员</el-checkbox>
          <el-checkbox label="2">收费员</el-checkbox>
          <el-checkbox label="3">巡检员</el-checkbox>
          <el-checkbox label="4">收费巡检混合</el-checkbox>
          <el-checkbox label="5">维修员</el-checkbox>
          <el-checkbox label="6">应急人员</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="人员类型" v-if="notifyData.rangeType=='2'">
        <el-select v-model="notifyData.roleType">
          <el-option label="管理员" value="1"></el-option>
          <el-option label="收费员" value="2"></el-option>
          <el-option label="巡检员" value="3"></el-option>
          <el-option label="收费巡检混合" value="4"></el-option>
          <el-option label="维修员" value="5"></el-option>
          <el-option label="应急人员" value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="notifyData.rangeType=='2'" label="通知人员">
        <el-select v-model="notifyData.userId" filterable remote placeholder="请输入姓名查询" :remote-method="getParkWorkerList">
          <el-option v-for="(item,key) in parkWorkerList" :key="key" :label="`${item.nickName}[${item.mobile}]-${item.isOnLine=='1'?'在线':'离线'}`" :value="item.userId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :required="true" label="通知内容">
        <el-input style="width:100%" type="textarea" v-model="notifyData.message" :rows="5" placeholder="请输入您要通知的内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="notifyMessage">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "createRecordMessage",
  title: "新建消息推送",
  data() {
    return {
      notifyData: {
        parkId: "",
        rangeType: "1",
        roleTypeList: [],
        roleType: "",
        userId: "",
        message: "",
      },
      parkingAreaList: [],
      parkWorkerList: [],
    };
  },
  methods: {
    notifyMessage() {
      if (this.notifyData.rangeType == "1") {
        this.notifyData.roleType = this.notifyData.roleTypeList.join();
      }
      this.$http.post(this.$api.notifyMessage, this.notifyData, (res) => {
        this.$message.success("推送消息发送成功！");
        Object.assign(this.$data.notifyData, this.$options.data().notifyData);
        this.$root.$emit("closeWorkorderDialog");
      });
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
    getParkWorkerList(query) {
      if (this.notifyData.parkId && this.notifyData.roleType) {
        this.$http.post(
          this.$api.selectEmpList,
          {
            currentPage: 1,
            pageSize: 10,
            parkId: this.notifyData.parkId,
            roleType: this.notifyData.roleType,
            nickName: query || "",
          },
          (res) => {
            this.parkWorkerList = res.list;
          }
        );
      } else {
        this.$message.info("请先选择要通知的停车场！");
      }
    },
  },
};
</script>