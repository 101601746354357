export default {
    install: (Vue) => {
        Vue.prototype.$baseData = {
            parkingType: [
                { lable: '平面停车场', value: '1' },
                { lable: '遥控停车场', value: '2' },
                { lable: '机械停车场', value: '3' },
                { lable: '立体停车场', value: '4' },
                { lable: '高位视频+地锁', value: '5' },
                { lable: '低位视频+地锁', value: '6' },
                { lable: '地磁+PDA', value: '7' },
                { lable: '纯PDA', value: '8' },
                { lable: '纯低位视频', value: '9' },
                { lable: '纯高位视频', value: '10' },
            ]
        }
    }
}