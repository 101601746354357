<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="searchData.plate" placeholder="请输入车牌号查找"></el-input>
        </el-form-item>
        <el-form-item label="审批状态">
          <el-select v-model="searchData.approvalStatus" placeholder="请选择">
            <el-option value label="全部"></el-option>
            <el-option value="0" label="待审批"></el-option>
            <el-option value="1" label="审批通过"></el-option>
            <el-option value="2" label="审批驳回"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.selectCarAppealList" :data="searchData" ref="table" width="200">
      <el-table-column label="序号" type="index" min-width="60"></el-table-column>
      <el-table-column label="绑定人" width="200">
        <template slot-scope="scope">
          <p>{{scope.row.bindingName}}【{{scope.row.bindingMobile}}】</p>
        </template>
      </el-table-column>
      <el-table-column label="申诉人" width="200">
        <template slot-scope="scope">
          <p>{{scope.row.name}}【{{scope.row.mobile}}】</p>
        </template>
      </el-table-column>
      <el-table-column label="争议车牌" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.plate==null" type="danger">无牌车</el-tag>
          <el-tag v-else type="success">{{scope.row.plate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="drivingLicenseImg" label="申诉资料照片" width="120">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.drivingLicenseImg" :preview-src-list="[scope.row.drivingLicenseImg]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申诉时间" min-width="150"></el-table-column>
      <el-table-column label="审批人姓名" min-width="120">
        <template slot-scope="scope">
          {{scope.row.approvalAdminName?scope.row.approvalAdminName:'待审批'}}
        </template>
      </el-table-column>
      <el-table-column label="审批备注" min-width="170">
        <template slot-scope="scope">
          {{scope.row.remark?scope.row.remark:'--'}}
        </template>
      </el-table-column>
      <el-table-column prop="approvalStatus" label="审批状态" width="110">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.approvalStatus==0" type="primary">待审批</el-tag>
          <el-tag size="medium" v-if="scope.row.approvalStatus==1" type="success">审批通过</el-tag>
          <el-tag size="medium" v-if="scope.row.approvalStatus==2" type="danger">已驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="110">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.approvalStatus!=0" type="text" @click="handle(scope.row.id,'1')">通过</el-button>
          <el-button :disabled="scope.row.approvalStatus!=0" type="text" @click="handle(scope.row.id,'2')">驳回</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "matterAppeal",
  title: "车辆申诉",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        mobile: "",
        plate: "",
        approvalStatus: "",
      },
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.approvalStatus = this.$route.query.status;
    }
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
  },
  methods: {
    handle(id, status) {
      this.$prompt("请输入审核备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http.post(
            this.$api.checkCarApprovalInfo,
            {
              approvalStatus: status,
              id: id,
              remark: value,
            },
            (res) => {
              this.$message.success("申诉处理成功！");
              this.$refs.table.reloadData();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>